
import classNames from 'classnames/bind';
import styles from '../knowledgeBase.module.scss';
import { Flexbox } from 'components';
import { useSelector } from 'react-redux';
import { currentKbModifiedDateSelector, currentKbOwnerSelector } from 'store/knowledgeBase';
import { LastUpdateIcon, PersonOutlineOutlinedIcon } from 'components/icons';
const classes = classNames.bind(styles);


const Info = () => {
    const currentKbOwner = useSelector(currentKbOwnerSelector)
    const lastModifiedDate = useSelector(currentKbModifiedDateSelector)

    return(
        <>
            <Flexbox align className={classes('infoItem')}>
                <Flexbox className={classes('icon')}>
                    <PersonOutlineOutlinedIcon />
                </Flexbox>
                <Flexbox>
                    {currentKbOwner.fullName}
                </Flexbox>
            </Flexbox>
            <Flexbox align className={classes('infoItem')}>
                <Flexbox className={classes('icon')}>
                    <LastUpdateIcon />
                </Flexbox>
                <Flexbox>
                    {new Date(lastModifiedDate).toLocaleDateString()}
                </Flexbox>
            </Flexbox>
        </>
    )
}

export default Info