import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Flexbox, HorizontalSeparator } from 'components';
import { FC, useMemo } from 'react';
import CollapseItem from '../../../../common/resourcesBlock/link/collapseItem';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_ADD_BLOCK_ITEM, ACTION_REPLACE_ATTACHMENT, ACTION_SET_RETRY_UPLOAD_UUID, ACTION_UPDATE_ATTACHMENT, currentKbOwnerSelector, knowledgeBaseCurrentBlockSelector, knowledgeBaseCurrentId } from 'store/knowledgeBase';
import { AttachmentMetadata, Block, isAttachmentBlock } from 'utils/types';
import AttachmentItem from './attachmentItem';
import FileSelector from 'components/FileUploader';
import { blocksBaseUrl } from 'pages/KnowledgeBase/knowledgeBase.api';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);

interface AttachmentProps {
    onSuccessCallback: any
}

const Attachment: FC<AttachmentProps> = ({ onSuccessCallback }) => {
    const dispatch = useDispatch();
    const ownerSelector = useSelector(currentKbOwnerSelector);

    const blocks = useSelector(knowledgeBaseCurrentBlockSelector);
    const currentKbId = useSelector(knowledgeBaseCurrentId);

    const attachments = useMemo(() => {
        const attachmentsBlock = blocks.filter(b => isAttachmentBlock(b)) as Block<AttachmentMetadata>[]
        return attachmentsBlock
    }, [blocks])

    const onUploadFinish = (uploadedItem: Block<AttachmentMetadata>) => {
        dispatch({ type: ACTION_REPLACE_ATTACHMENT, payload: uploadedItem })
    }

    const onUploadError = (fileName: string) => {
        dispatch({ type: ACTION_UPDATE_ATTACHMENT, payload: { isError: true, fileStatus: 0, fileName } })
    }

    const onUploadProgress = (_fileName: string, status: number) => {
        dispatch({ type: ACTION_UPDATE_ATTACHMENT, payload: { fileStatus: status, fileName: _fileName } })
    }

    const onFileUploadStart = (attachment: Block<AttachmentMetadata>) => {
        dispatch({ type: ACTION_ADD_BLOCK_ITEM, payload: attachment })
    }

    const resetRetryCondition = () => {
        dispatch({ type: ACTION_SET_RETRY_UPLOAD_UUID, payload: null })
    }

    return(
        <>
            <CollapseItem title='Attachments'>
                {attachments.map(attachment => <AttachmentItem attachment={attachment} key={attachment.id || attachment.uuId} />)}
            </CollapseItem>
            <Flexbox>
                <Flexbox>
                    { hasPermission(Actions.edit, { owner: ownerSelector }) &&
                        <FileSelector
                            url={blocksBaseUrl(currentKbId)}
                            buttonType='button'
                            buttonText='Add new attachment'
                            onUploadFinish={onUploadFinish}
                            onUploadProgress={onUploadProgress}
                            onFileUploadStart={onFileUploadStart}
                            onUploadError={onUploadError}
                            resetRetryCondition={resetRetryCondition}
                            onSuccessCallback={onSuccessCallback}
                        />
                    }
                </Flexbox>
            </Flexbox>
            <HorizontalSeparator className={classes('separator')} dashed />
        </>
    )
}

export default Attachment