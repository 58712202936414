import { ConfirmationDialog, FilterButton, Flexbox, Loader, SearchField } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OKR, Owner, PreferenceValues, Preferences, PreferencesKeys, Report, ReportCreateModel, ReportVariantTypes, Team } from 'utils/types';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './reportsList.module.scss';
import classNames from 'classnames/bind';
import { Actions, hasPermission } from 'utils/permissions';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { EmptyReportIcon, OkrIcon, TeamIcon } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, deleteReport, generateReport, getReports } from './reports.api';
import { reportsSelector } from 'store/reports';
import CreateReportDialog from './components/createReportDialog';
import { useDebounce } from 'utils/hooks';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import ActionsButton from 'components/ActionsButton';
import { FilterOption } from 'components/FilterButton';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import { getEnumKeyByEnumValue } from 'utils';
import AgGridTable, { AgColumn, ColumnTypes } from 'components/AgGridTable';
import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import EmptyState from 'common/emptyState';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import * as timeago from 'timeago.js';
const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

export interface ReportListData{
    id: number;
    title: string;
    teams: Team[];
    okrs: OKR[];
    owner: Owner;
    lastGeneratedDate: number;
    type: string
}

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    reportVariant = 'reportVariant',
}

const defaultFilterState = {
    [FilterKeys.reportVariant]: [],
}

const filterValuesReducer = (state: {[key: string]: FilterOption[]}, action: FilterValuesReducerAction) => {
    switch(action.type) {
    case 'update':
        return { ...state, [action.key] : action.payload }
    case 'reset':
        return defaultFilterState;
    default:
        return state;
    }
}

const ReportsList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const reports = useSelector(reportsSelector)

    const [data, setData] = useState<ReportListData[]>([])
    const [loading, setLoading] = useState(true);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [createDialog, setCreateDialog] = useState(false)
    const [reportId, setReportId] = useState<number | undefined>();

    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [searchParams, setSearchParams] = useSearchParams();

    const [orderBy, setOrderBy] = useState<keyof ReportListData | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [reportType, setReportType] = useState<ReportVariantTypes>(ReportVariantTypes['By Okr']);
    const [createLoading, setCreateLoading] = useState(false)

    const [teamGridApi, setTeamGridApi] = useState<GridApi<any> | null>(null)
    const [okrGridApi, setOkrGridApi] = useState<GridApi<any> | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getReports()),
                loadPreferences()
            ])
            setLoading(false)
        }
        setLoading(true);
        fetchData();
    }, [])

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.report))) as unknown as Preferences<FilterKeys>[];
        if(preferences && preferences.length) {
            const filters: PreferenceValues<FilterKeys> = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;
            if(searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        let data = [...reports];

        if(searchDebounceValue) {
            data = data.filter(d => d.title && d.title.toLowerCase().includes(searchDebounceValue.toLowerCase())
            || d.owner.fullName && d.owner.fullName.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        if(orderBy && order) {
            data.sort((d1, d2) => {
                if(orderBy !== 'id'){
                    if(orderBy === 'lastGeneratedDate'){
                        const value1 = d1[orderBy] || '';
                        const value2 = d2[orderBy] || '';
                        if(value1 > value2) {
                            return order === 'asc' ? 1 : -1
                        } else{
                            return order === 'asc' ? -1 : 1
                        }
                    }
                    if(orderBy === 'owner'){
                        const createdBy1 = d1[orderBy]?.fullName || '';
                        const createdBy2 = d2[orderBy]?.fullName || '';
                        if(createdBy1.toUpperCase() > createdBy2.toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        } else {
                            return order === 'asc' ? -1 : 1
                        }
                    }
                    if(orderBy === 'teams'){
                        const team1 = d1[orderBy][0]?.name || '';
                        const team2 = d2[orderBy][0]?.name || '';
                        if(team1.toUpperCase() > team2.toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        } else {
                            return order === 'asc' ? -1 : 1
                        }
                    }
                    else if(orderBy === 'title'){
                        const value1 = d1[orderBy] || '';
                        const value2 = d2[orderBy] || '';
                        if(value1.toUpperCase() > value2.toUpperCase()) {
                            return order === 'asc' ? 1 : -1
                        } else {
                            return order === 'asc' ? -1 : 1
                        }
                    }
                }
                return 0
            })
        }

        data = data.filter(d => {
            const reportType = getEnumKeyByEnumValue(ReportVariantTypes, d.type);
            if(!filterValues[FilterKeys.reportVariant].length || filterValues[FilterKeys.reportVariant].some(filter => filter.title === reportType)) {
                return true;
            }
            return false
        })

        setData(data)

    }, [reports, order, orderBy, searchDebounceValue, filterValues]);

    useEffect(() => {
        if(canUpdatePreferences) {
            const reportVariants = filterValues[FilterKeys.reportVariant].map(filter => filter.title);
            const filterKeys: PreferenceValues<FilterKeys> = {};

            if(searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if(order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            if(reportVariants.length) {
                filterKeys[FilterKeys.reportVariant] = reportVariants.join(',');
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.report));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy]);


    useEffect(() => {
        if(!loading) {
            const queryString = searchParams.get(FilterKeys.query);
            if(queryString) {
                setSearchValue(queryString);
            }

            const orderString = searchParams.get(FilterKeys.order);
            const orderByString = searchParams.get(FilterKeys.orderBy);
            if((orderString === 'asc' || orderString === 'desc') && orderByString) {
                setOrder(orderString);
                setOrderBy(orderByString as keyof ReportListData);
            }

            const reportVariantsString = searchParams.get(FilterKeys.reportVariant);
            if(reportVariantsString) {
                const reportVariant = reportVariantsString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.reportVariant, payload: reportVariantOptions.filter(option => reportVariant.includes(option.title)) })
            }

        }
    }, [loading])

    const reportVariantOptions = useMemo(() => {
        return Object.keys(ReportVariantTypes).map((key, index) => ({ id: index, title: key }))
    }, [])

    const showDeleteConfirmation = (id: number) => {
        setReportId(id)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const onDeleteReport = () => {
        if(reportId){
            dispatch(deleteReport(reportId))
            onCancelDelete()
        }
    }

    const generateReportByTeam = () => {
        setReportType(ReportVariantTypes['By Team'])
        setCreateDialog(true)
    }

    const generateReportByOkr = () => {
        setReportType(ReportVariantTypes['By Okr'])
        setCreateDialog(true)
    }

    const closeDialog = () => {
        setCreateDialog(false)
    }

    const onConfirmDialog = async (reportSaveData: ReportCreateModel) => {
        setCreateLoading(true)
        const newReport: Report = (await dispatch(createReport(reportSaveData))) as unknown as Report
        if(newReport.id){
            await dispatch(generateReport(newReport.id))
            setCreateLoading(false)
            setCreateDialog(false)
            navigate(`/reports/report/${newReport.id}`);
        }
    }

    const sortHandler = (header: keyof ReportListData) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        });
        setCanUpdatePreferences(true);
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if(hasPermission(Actions.create)){
            buttons.push({
                onClick: generateReportByTeam,
                text: 'Generate By Team',
            },
            {
                onClick: generateReportByOkr,
                text: 'Generate By OKR',
            }
            )
        }

        return buttons
    },[])

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value })
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] })
        setCanUpdatePreferences(true)
    }

    const okrCols: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'Title',
            field: 'title',
            sortable: true,
            link: '/reports/report',
            linkParam: 'id',
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell ag-title',
        },
        {
            colType: ColumnTypes.Chip,
            headerName: 'Teams',
            field: 'teams',
            sortable: true,
            selectorName: 'name',
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Created by',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            colType: ColumnTypes.TimeAgo,
            headerName: 'Last generated date',
            field: 'lastGeneratedDate',
            minWidth: 200,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params =>  {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/reports/report/${params?.node?.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.delete, params.data) ? [
                        { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                    ] : [])
                ]
            },
        },
    ], [])

    const teamCols: AgColumn[] = useMemo(() => [
        {
            colType: ColumnTypes.Linked,
            headerName: 'Title',
            field: 'title',
            sortable: true,
            link: '/reports/report',
            linkParam: 'id',
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell ag-title',
        },
        {
            colType: ColumnTypes.Chip,
            headerName: 'OKRs',
            field: 'okrs',
            sortable: true,
            selectorName: 'objective',
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Created by',
            field: 'owner',
            minWidth: 200,
            sortable: true,
            valueGetter: (params) => {
                return params.data ? params.data.owner?.fullName : '';
            },
            valueFormatter: (params) => {
                return params?.value?.fullName;
            },
        },
        {
            colType: ColumnTypes.TimeAgo,
            headerName: 'Last generated date',
            field: 'lastGeneratedDate',
            minWidth: 200,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            actions: params =>  {
                return [
                    {
                        label: 'Open',
                        action: () => {
                            navigate(`/reports/report/${params?.node?.data?.id}`)
                        }
                    },
                    ...(hasPermission(Actions.delete, params.data) ? [
                        { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                    ] : [])
                ]
            },
        },
    ], [])

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if(value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if(order !== value?.sort || orderBy !== value?.colId) {
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.report));
        }
    }

    const onTeamGridReady = useCallback((e: GridReadyEvent) => {
        setTeamGridApi(e.api)
    }, [])

    const onOkrGridReady = useCallback((e: GridReadyEvent) => {
        setOkrGridApi(e.api)
    }, [])

    return(
        <Flexbox fullWidth vertical>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>
                        Reports
                    </Flexbox>
                    <Flexbox>
                        { teamGridApi && (
                            <ExportButton
                            api={teamGridApi}
                            text='Export By Team'
                            formatExportedCellValue= {
                                    (colId: string, value: any, formattedValue: any) => {
                                        if (colId === 'lastGeneratedDate') {
                                            return value ? timeago.format(value) : '';
                                        } else {
                                            return formattedValue
                                        }
                                    }
                                }
                            />
                        ) }
                        { okrGridApi && (
                            <ExportButton
                                api={okrGridApi}
                                text='Export By OKR'
                                formatExportedCellValue={
                                    (colId: string, value: any, formattedValue: any) => {
                                        if (colId === 'lastGeneratedDate') {
                                            return value ? timeago.format(value) : '';
                                        } else {
                                            return formattedValue
                                        }
                                    }
                                }
                            />
                        ) }
                        {reports.length > 0 && hasPermission(Actions.create) &&
                            <ActionsButton
                                buttonItems={[
                                    { label: 'By Team', action: generateReportByTeam, icon: <TeamIcon /> },
                                    { label: 'By OKR', action: generateReportByOkr, icon: <OkrIcon /> },
                                ]}
                                className={classes('actionMenu')}
                                btnClassName={classes('actionMenu-btn')}
                                buttonText='Generate'
                            />
                        }
                    </Flexbox>
                </Flexbox>
                {reports.length > 0 &&
                 <Flexbox className={classesInfo('headerInfo')}>
                     <Flexbox>
                         <SearchField
                             value={searchValue}
                             onChange={onSearchValueChange}
                             onClear={onSearchClear}
                             placeholder='Search Report'
                             className={classesInfo('searchInput')}
                         />
                         <FilterButton
                             options={reportVariantOptions}
                             value={filterValues[FilterKeys.reportVariant]}
                             onChange={(value) => onFilterValueChange(FilterKeys.reportVariant, value)}
                             onFilterReset={() => onFilterReset(FilterKeys.reportVariant)}
                             multiple
                             sortAlphabetically={false}
                             label='Report by'
                             className={classesInfo('filterButton')}
                         />
                     </Flexbox>
                 </Flexbox>
                }
            </Flexbox>
            <Flexbox fullHeight>
                {loading ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink/></Flexbox>
                    :
                    reports.length > 0 ? <Flexbox vertical fullWidth>
                        {data.some(({ teams }) => teams && teams.length > 0) &&
                            <Flexbox vertical className={classes('tableContainer')} style={{
                                height: '100%'
                            }}>
                                <Flexbox className={classes('title')}>
                                    By Team
                                </Flexbox>
                                <AgGridTable
                                    data={data.filter(byTeam => byTeam.teams.length)}
                                    columns={okrCols}
                                    getContextMenuItems={params => {
                                        if(params.defaultItems) {
                                            return ([
                                                ...(params.defaultItems ?? []),
                                                {
                                                    name: 'Open in new tab',
                                                    action: () => {
                                                        const rowData = params?.node?.data;
                                                        const url = `/reports/report/${rowData.id}`;
                                                        window.open(url, '_blank');
                                                    }
                                                }
                                            ]);
                                        } else {
                                            return []
                                        }
                                    }}
                                    order={order}
                                    orderBy={orderBy}
                                    onSortChanged={onSortChanged}
                                    onGridReady={onTeamGridReady}
                                    exportFileName='Reports By Team'
                                />
                            </Flexbox>
                        }
                        {data.some(({ okrs }) => okrs && okrs.length > 0) &&
                            <Flexbox vertical className={classes('tableContainer')} style={{
                                height: '100%'
                            }}>
                                <Flexbox className={classes('title')}>
                                    By OKR
                                </Flexbox>
                                <AgGridTable
                                    data={data.filter(byOkr => byOkr.okrs.length)}
                                    columns={teamCols}
                                    getContextMenuItems={params => {
                                        if(params.defaultItems) {
                                            return ([
                                                ...(params.defaultItems ?? []),
                                                {
                                                    name: 'Open in new tab',
                                                    action: () => {
                                                        const rowData = params?.node?.data;
                                                        const url = `/reports/report/${rowData.id}`;
                                                        window.open(url, '_blank');
                                                    }
                                                }
                                            ]);
                                        } else {
                                            return []
                                        }
                                    }}
                                    order={order}
                                    orderBy={orderBy}
                                    onSortChanged={onSortChanged}
                                    onGridReady={onOkrGridReady}
                                    exportFileName='Reports By OKR'
                                />
                            </Flexbox>
                        }
                    </Flexbox> : (
                        <EmptyState
                            icon={<EmptyReportIcon />}
                            title='There are no Reports yet'
                            titleSmall={buttonItemsEmptyState.length ? 'Press the button and start to' : undefined}
                            buttonItems={buttonItemsEmptyState}
                        />
                    )}
            </Flexbox>

            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteReport}
                confirmButtonStyle='danger'
                title='Delete this Report?'
            >
                <Flexbox>
                    You're about to permanently delete this Report.
                </Flexbox>
            </ConfirmationDialog>

            {createDialog && <CreateReportDialog open={createDialog} onClose={closeDialog} onConfirm={onConfirmDialog} reportType={reportType} createLoading={createLoading} />}

        </Flexbox>
    )
}

export default ReportsList