

import { ActionsMenu, Flexbox, IconButton, LinearProgress, Tooltip, } from 'components'
import { AttachmentMetadata, Block, } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAction } from 'common/resourcesBlock/index.api';
import { ACTION_CANCEL_UPLOAD, ACTION_DELETE_BLOCK_ITEM, ACTION_SET_RETRY_UPLOAD_UUID, currentKbOwnerSelector, knowledgeBaseCurrentId } from 'store/knowledgeBase';
import { blocksBaseUrl } from 'pages/KnowledgeBase/knowledgeBase.api';
import { Actions, hasPermission } from 'utils/permissions';
import { CancelIcon, ReplayIcon } from 'components/icons';
const classes = classNames.bind(styles);


interface AttachmentItemProps{
    attachment: Block<AttachmentMetadata>;
}

const AttachmentItem = ({ attachment }: AttachmentItemProps) => {
    const dispatch = useDispatch();
    const currentKbId = useSelector(knowledgeBaseCurrentId);
    const ownerSelector = useSelector(currentKbOwnerSelector);

    const deleteAttachment = () => {
        dispatch(deleteAction(attachment.id, blocksBaseUrl(currentKbId)))
        dispatch({ type: ACTION_DELETE_BLOCK_ITEM, payload: attachment.id })
    }

    const cancelAttachmentUpload = () => {
        dispatch({ type: ACTION_CANCEL_UPLOAD, payload: attachment.uuId })
    }

    const retryFileUpload = () => {
        dispatch({ type: ACTION_SET_RETRY_UPLOAD_UUID, payload: attachment.uuId })
    }

    return(
        <Flexbox className={classes('attachmentContainer')}>
            <Flexbox className={classes('linkContainer')}>
                <a aria-disabled className={classes('linkText', { 'disabled-link': attachment.metadata.disabled })} href={attachment.metadata.signedUrl} target="_blank" rel="noopener">
                    {attachment.metadata.fileName}
                </a>
                {!attachment.metadata.disabled && hasPermission(Actions.delete, { owner: ownerSelector }) &&
                <ActionsMenu
                    className={classes('linkActionsMenu')}
                    buttonItems={[
                        {
                            label: 'Delete',
                            action: deleteAttachment,
                            type: 'red'
                        }
                    ]}
                />
                }
            </Flexbox>

            {
                !!attachment.metadata.fileStatus && <LinearProgress value={attachment.metadata.fileStatus} variant='determinate' className={classes('uploadProgress')} />
            }

            { attachment.metadata.isError &&
                <>
                    <Tooltip title="Retry">
                        <IconButton onClick={retryFileUpload} className={classes('actionIcon')}>
                            <ReplayIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <IconButton onClick={cancelAttachmentUpload} className={classes('actionIcon')}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Flexbox>
    )
}

export default AttachmentItem