
import MSnackbar, { SnackbarCloseReason, SnackbarProps as MSnackbarProps } from '@mui/material/Snackbar';
import classNames from 'classnames/bind';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, AlertColor } from '@mui/material';
import { Tick, Close, ErrorAlert, ErrorCircle } from 'components/icons';
import { Flexbox, IconButton, CustomTyphography } from 'components';
import styles from './styles.module.scss';

const classes = classNames.bind(styles);

interface SnackbarProps extends MSnackbarProps {
    type: AlertColor;
    closeOnClickAway?: boolean;
    description?: string
}

const CustomSnackbar = ({ type, closeOnClickAway, onClose, children, title, description, open, ...props }: SnackbarProps) => {
    const [openState, setOpenState] = useState(open)

    useEffect(() => {
        setOpenState(open)
    }, [open])

    const close = (event?: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway' && !closeOnClickAway) {
            return
        }

        if (onClose && event && reason) {
            onClose(event, reason)
        }

        setOpenState(false)
    }

    return (
        <MSnackbar
            open={openState}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            className={classes('snackbarContainer')}
            onClose={close}
            classes={{
                anchorOriginBottomLeft: classes('bottomLeft')
            }}
            {...props}
        >
            <Alert
                severity={type}
                iconMapping={{
                    success: <Tick />,
                    warning: <ErrorAlert />,
                    error: <ErrorCircle />,
                }}
                classes={{
                    standardSuccess: classes('success'),
                    standardWarning: classes('warning'),
                    standardError: classes('error'),
                }}
            >
                <Flexbox vertical className={classes('snackbar-textcontent')}>
                    <CustomTyphography type='primary' className={classes('font-500')}>{title}</CustomTyphography>
                    <CustomTyphography type='primary' className={classes('snackbar-textcontent--description')}>{description}</CustomTyphography>
                </Flexbox>
                <IconButton onClick={close} className={classes('closeButton')}><Close /></IconButton>
            </Alert>
        </MSnackbar>
    )
}

export default CustomSnackbar