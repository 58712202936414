import { useState, MouseEvent, FC } from 'react';
import { ActionsMenu, CustomTyphography, Flexbox, IconButton, Tooltip } from 'components'
import { NavLink } from 'react-router-dom';
import { SidebarMenuItem } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './menuItem.module.scss';
import { Settings } from 'components/icons';
import { ButtonItem } from 'components/ActionsMenu';
const classes = classNames.bind(styles);


interface MenuItemProps {
    item: SidebarMenuItem;
    onClick?: () => void;
    className?: string,
    isCollapsed?: boolean,
    titleClassName?: string,
    settings?: boolean,
    buttonItems?: ButtonItem[]
}

const MenuItem: FC<MenuItemProps> = ({
    item,
    onClick = () => { },
    className,
    isCollapsed,
    titleClassName,
    settings,
    buttonItems
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    if (item.hasPopupMenu) {
        return (
            <Flexbox className={classes('menuItemContainer', className)}>
                <Flexbox className={classes('menuItem')} onClick={handleClick}>
                    {
                        item.icon && <Tooltip placement="right" title={isCollapsed ? 'Account' : ''}><Flexbox><item.icon /></Flexbox></Tooltip>
                    }
                    {!isCollapsed &&
                        <CustomTyphography className={classes('popupMenuItemTitle')} type='primary'>
                            {item.title}
                        </CustomTyphography>
                    }
                </Flexbox>
                <item.component anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </Flexbox>
        )
    }

    return item.subMenus?.length ? (
        <Flexbox className={classes('menuItemContainer')}>
            <Flexbox className={classes('menuItem')} onClick={onClick}>
                {
                    item.icon && <item.icon />
                }
                {!isCollapsed &&
                    <CustomTyphography type='primary'>
                        {item.title}
                    </CustomTyphography>
                }
            </Flexbox>
        </Flexbox>
    ) : (
        <NavLink
            key={item.url}
            to={item.paramId ? `/${item.url.replace(':id', item.paramId)}` : `/${item.url}`} className={({ isActive }) => {
                return classes('menuItemContainer', { 'active': isActive })
            }}
        >
            <Tooltip placement="right" title={item.title}>
                <Flexbox justifyBetween align fullWidth onClick={onClick} className={classes({ menuItem__settings: settings })}>
                    <Flexbox className={classes('menuItem')} justify={isCollapsed}>
                        {
                            item.icon && (
                                <Flexbox>
                                    <item.icon />
                                </Flexbox>
                            )
                        }
                        {!isCollapsed && (
                            <CustomTyphography type='primary' className={classes(titleClassName)}>{item.title}</CustomTyphography>
                        )}
                    </Flexbox>
                    {(settings && buttonItems) && (
                        // <IconButton className={classes('settingsBtn')}><Settings /></IconButton>
                        <Flexbox className={'pr-4 h-fit'}>
                            <ActionsMenu
                                buttonItems={buttonItems}
                            />
                        </Flexbox>
                    )}
                </Flexbox>
            </Tooltip>
        </NavLink>
    )
}

export default MenuItem