import { useState, MouseEvent } from 'react';
import { Flexbox, HorizontalSeparator, Menu, MenuItem } from 'components';
import { AdminPanelIcon, LogoutIcon, UserIcon } from 'components/icons';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user';
import { getInitialsFromName } from 'utils';
import { logout } from 'pages/Home/home.api';


import classNames from 'classnames/bind';
import styles from './userAccount.module.scss';
import { SidebarPopupMenuProp } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);



const UserAccount = ({ anchorEl, setAnchorEl }: SidebarPopupMenuProp) => {
    const user = useSelector(userSelector)
    const navigate = useNavigate();
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogout = async () => {
        await logout()
    }

    const goToAdmin = () => {
        navigate('/users');
    }


    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handleClose}
            classes={{
                root: classes('menuContainer'),
                paper: classes('menuPaper')
            }}
        >
            <Flexbox className={classes('header')}>
                    Account
            </Flexbox>
            <Flexbox className={classes('userInfoContainer')}>
                <Flexbox className={classes('userInitials')}>{user.fullName && getInitialsFromName(user.fullName)}</Flexbox>
                <Flexbox vertical>
                    <Flexbox className={classes('name')}>{user.fullName}</Flexbox>
                    <Flexbox className={classes('email')}>{user.email}</Flexbox>
                </Flexbox>
            </Flexbox>
            <HorizontalSeparator dashed/>
            { hasPermission(Actions.adminPanel) && (
                <MenuItem
                    onClick={goToAdmin}
                >
                    <Flexbox className={classes('logoutContainer')}><Flexbox className={classes('logoutIcon')}><AdminPanelIcon/></Flexbox>Admin Panel</Flexbox>
                </MenuItem>
            )}
            <MenuItem
                onClick={onLogout}
            >
                <Flexbox className={classes('logoutContainer')}><Flexbox className={classes('logoutIcon')}><LogoutIcon/></Flexbox>Log out</Flexbox>
            </MenuItem>
        </Menu>
    )
}

export default UserAccount