import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionsMenu, Flexbox, Loader, } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind';
import styles from './knowledgeBase.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import { createKb, getKBbyID } from './knowledgeBase.api'
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);
import { Attachment, Content, DeleteModal, Info, Status, Title } from './components'
import { Actions, hasPermission } from 'utils/permissions';
import { ACTION_ADD_BLOCK_ITEM, ACTION_DELETE_BLOCK_ITEM, ACTION_UPDATE_BLOCK_ITEM, currentKbOwnerSelector, knowledgeBaseCurrentBlockSelector, knowledgeBaseCurrentId } from 'store/knowledgeBase';
import { AttachmentMetadata, Block, KnowledgeBase as KnowledgeBaseType, LinkMetadata, TextMetadata, isLinkBlock } from 'utils/types';
import LinkResources from 'common/resourcesBlock/link';

export const defaultTitle = 'Untitled knowledge';

const KnowledgeBase = () => {
    const params = useParams();
    const navigate = useNavigate();
    const kbId = params['id'] ? parseInt(params['id']) : undefined;

    const dispatch = useDispatch();
    const ownerSelector = useSelector(currentKbOwnerSelector);

    const isEditable = hasPermission(Actions.edit, { owner: ownerSelector });

    const currentKbId = useSelector(knowledgeBaseCurrentId);

    const baseUrl = `/knowledge-base/${currentKbId}/blocks/`;

    const blocks: Block<LinkMetadata | AttachmentMetadata | TextMetadata>[] = useSelector(knowledgeBaseCurrentBlockSelector);

    const links = useMemo(() => {
        const linksBlock = blocks?.filter(b => isLinkBlock(b)) as Block<LinkMetadata>[]
        return linksBlock || []
    }, [blocks])

    const [isLoading, setIsLoading] = useState(true);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

    useEffect(() => {
        if(!kbId){
            createKbHandler()
        } else{
            loadKb()
        }
    },[kbId])

    const createKbHandler = async () => {
        const newKb = (await dispatch(createKb())) as unknown as KnowledgeBaseType;
        navigate(`/knowledge-base/knowledge/${newKb.id}`, { replace: true });
        setIsLoading(false)
    }

    const loadKb = async () => {
        if(kbId && kbId !== currentKbId){
            await dispatch(getKBbyID(kbId))
        }
        setIsLoading(false)
    }

    const showDeleteConfirmation = () => {
        setOpenDeleteConfirmation(true)
    }

    const sucessCallback = async () => {
        if(kbId) {
            await dispatch(getKBbyID(kbId))
        }
    }

    const deleteLinkAction = async (id: number) => {
        dispatch({ type: ACTION_DELETE_BLOCK_ITEM, payload: id })
    }

    const editLinkAction = async (data: any) => {
        dispatch({ type: ACTION_UPDATE_BLOCK_ITEM, payload: data })
    }

    const createLinkAction = async (data: Block<LinkMetadata>) => {
        dispatch({ type: ACTION_ADD_BLOCK_ITEM, payload: data })
    }

    return(isLoading ? <Flexbox fullWidth align justify><Loader/></Flexbox> :
        <Flexbox vertical fullWidth>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Title />
                    <Flexbox align className={classes('infoTitleBoxRight')}>
                        <Info />
                        <Status />
                        {isEditable &&
                         <ActionsMenu className={classes('actionsMenu')}
                             buttonItems={[{ label: 'Delete', action: showDeleteConfirmation, type: 'red' }]}
                         />
                        }
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <Flexbox vertical className={classes('container')}>
                <Attachment onSuccessCallback={sucessCallback}/>
                <LinkResources links={links} isEditable={isEditable} baseUrl={baseUrl} deleteLinkAction={deleteLinkAction} editLinkAction={editLinkAction} createLinkAction={createLinkAction} />
                <Content />
            </Flexbox>
            <DeleteModal openDeleteConfirmation={openDeleteConfirmation} setOpenDeleteConfirmation={setOpenDeleteConfirmation} />
        </Flexbox>
    )
}

export default KnowledgeBase