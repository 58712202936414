
const LastUpdateIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.0301 5.97785L7.8426 6.54702C7.90583 6.59137 7.95549 6.65243 7.98603 6.72337C8.01657 6.79431 8.0268 6.87234 8.01556 6.94875C8.00432 7.02516 7.97207 7.09695 7.9224 7.15609C7.87272 7.21524 7.80759 7.25941 7.73427 7.28368L4.91094 8.21452C4.84924 8.23495 4.78362 8.24067 4.71932 8.23121C4.65502 8.22175 4.59382 8.19738 4.54062 8.16005C4.48742 8.12273 4.44369 8.07347 4.41292 8.01622C4.38216 7.95897 4.36521 7.89532 4.36344 7.83035L4.2826 4.86618C4.28049 4.78898 4.29987 4.71271 4.33858 4.64589C4.3773 4.57906 4.43382 4.52431 4.50185 4.48775C4.56988 4.45119 4.64673 4.43425 4.72382 4.43883C4.80091 4.44341 4.87522 4.46933 4.93844 4.51368L5.6101 4.98368C6.75611 3.97993 8.21133 3.39896 9.73352 3.33747C11.2557 3.27598 12.753 3.73768 13.9763 4.64573C15.1995 5.55377 16.0749 6.85338 16.4566 8.32821C16.8383 9.80304 16.7034 11.3641 16.0743 12.7516C15.4453 14.1391 14.3599 15.2693 12.9991 15.954C11.6382 16.6387 10.0839 16.8367 8.59477 16.515C7.1057 16.1933 5.77172 15.3713 4.81489 14.1858C3.85807 13.0004 3.33612 11.523 3.33594 9.99952H5.00344C5.0037 11.1243 5.38324 12.2162 6.08072 13.0987C6.7782 13.9812 7.75286 14.6027 8.8472 14.8628C9.94154 15.1229 11.0916 15.0064 12.1116 14.5322C13.1315 14.0579 13.9618 13.2536 14.4681 12.2492C14.9745 11.2448 15.1274 10.099 14.9021 8.99694C14.6769 7.8949 14.0866 6.90102 13.2266 6.17591C12.3667 5.4508 11.2874 5.03682 10.1632 5.00088C9.03894 4.96494 7.93541 5.30914 7.03094 5.97785H7.0301Z" fill="currentColor"/>
        </svg>
    )
}

export default LastUpdateIcon;
