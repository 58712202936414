import { Dispatch, GetState } from 'store';
import { ACTION_CREATE_KB, ACTION_DELETE_KB, ACTION_GET_KB, ACTION_SET_CURRENT_KB, ACTION_SET_KB } from 'store/knowledgeBase';
import request from 'utils/request';
import { KnowledgeBase, KnowledgeBaseStatus } from 'utils/types';
import { defaultTitle } from './knowledgeBase';

export const getListKB = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    dispatch({ type: ACTION_GET_KB, payload: null })

    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/knowledge-base`);

    dispatch({ type: ACTION_SET_KB, payload: res.data })

}

export const createKb = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const userId = storeState.user.id

    const saveModel = {
        title: defaultTitle,
        owner: userId,
        status: KnowledgeBaseStatus.Draft,
    }

    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/knowledge-base`, saveModel)

    dispatch({ type: ACTION_CREATE_KB, payload: res.data })

    return res.data as KnowledgeBase
}

export const getKBbyID = (knowledgeBaseId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/knowledge-base/${knowledgeBaseId}`);

    dispatch({ type: ACTION_SET_CURRENT_KB, payload: res.data })
}

export const deleteKB = (knowledgeBaseId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    await request.delete(`/workspaces/${storeState.user.workspace.id}/knowledge-base/${knowledgeBaseId}`);

    dispatch({ type: ACTION_DELETE_KB, payload: knowledgeBaseId });
}

export const editKb = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const currentKb = storeState.knowledgeBase.knowledgeBaseCurrent

    const { blocks, createdDate, lastModifiedDate, content, ...kbWithoutBlocks } = currentKb;

    await request.put(`/workspaces/${storeState.user.workspace.id}/knowledge-base/${currentKb.id}`, kbWithoutBlocks)
}

export const blocksBaseUrl = (currentKbId: number) => {
    return `/knowledge-base/${currentKbId}/blocks/`
}