import { ConfirmationDialog, Flexbox } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteKB } from '../knowledgeBase.api';
import { knowledgeBaseCurrentSelector } from 'store/knowledgeBase';


interface DeleteModalProps{
    openDeleteConfirmation: boolean;
    setOpenDeleteConfirmation: (b: boolean) => void;
}

const DeleteModal = ({ openDeleteConfirmation, setOpenDeleteConfirmation }: DeleteModalProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const kBCurrentSelector = useSelector(knowledgeBaseCurrentSelector);

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const onDeleteKB = async () => {
        await dispatch(deleteKB(kBCurrentSelector.id))
        onCancelDelete()
        navigate('/knowledge-base');
    }

    return(
        <ConfirmationDialog
            open={openDeleteConfirmation}
            onClose={onCancelDelete}
            onConfirm={onDeleteKB}
            confirmButtonStyle='danger'
            title='Delete this Knowledge base?'
        >
            <Flexbox>
                You're about to permanently delete this Knowledge base.
            </Flexbox>
        </ConfirmationDialog>
    )
}

export default DeleteModal