import { Flexbox, PriorityIcon, SearchField, TabPanel, TimeAgo, Tooltip } from 'components';
import classNames from 'classnames/bind';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from '../../okr.module.scss';
import Table, { TableHeader } from 'components/Table';
import { Initiative, InitiativePriority, InitiativeStatus } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import { getEnumKeyByEnumValue } from 'utils';
import { ChangeEvent, useEffect, useState } from 'react';
import AutocompleteDialog from 'pages/Initiatives/components/autocompleteDialog';
import ActionsButton from 'components/ActionsButton';
import { Actions, hasPermission } from 'utils/permissions';
import { GenerateIcon, PlusIcon } from 'components/icons';
import { useDebounce } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { okrInitiativesSelector } from 'store/okr-slice';
import { sortByValue } from 'utils/tableSorting';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);


const initiativesTableHeader: TableHeader<Initiative>[] = [
    {
        id: 'owner',
        text: 'Owner',
        sortable: true,
    },
    {
        id: 'title',
        text: 'Title',
        sortable: true,
    },
    {
        id: 'products',
        text: 'Product',
        sortable: true,
    },
    {
        id: 'priority',
        text: 'P',
        sortable: true,
    },
    {
        id: 'status',
        text: 'Status',
        sortable: true,
    },
    {
        id: 'startDate',
        text: 'Start Date',
        sortable: true,
    },
    {
        id: 'releaseDate',
        text: 'Live Date',
        sortable: true,
    },
    {
        id: 'lastModifiedDate',
        text: 'Last Updated',
        sortable: true,
    },
];

interface InitiativesTabProps{
    active: boolean;
    okrId?: number
}

const InitiativesTab = ({ active, okrId }: InitiativesTabProps) => {
    const navigate = useNavigate();

    const initiatives = useSelector(okrInitiativesSelector)

    const [data, setData] = useState<Initiative[]>([])
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [orderBy, setOrderBy] = useState<keyof Initiative | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [openAutoCompleteDialog, setOpenAutoCompleteDialog] = useState(false);

    useEffect(() => {
        let data = [...initiatives];

        if(orderBy && order) {
            data.sort((d1, d2) => {
                if(orderBy === 'products'){
                    const products1 = d1[orderBy];
                    const products2 = d2[orderBy];
                    const title1 = products1[0]?.title || '';
                    const title2 = products2[0]?.title || '';

                    if(title1.toUpperCase() > title2.toUpperCase()) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                }else if(orderBy === 'owner'){
                    if(d1[orderBy].fullName.toUpperCase() > d2[orderBy].fullName.toUpperCase()) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                } else if(orderBy === 'priority') {
                    return sortByValue(d1.priority, d2.priority, order, InitiativePriority)
                } else if(orderBy === 'status') {
                    return sortByValue(d1.status, d2.status, order, InitiativeStatus)
                }
                else{
                    const value1 = d1[orderBy];
                    const value2 = d2[orderBy];
                    if(value1 && value2 && value1 > value2) {
                        return order === 'asc' ? 1 : -1
                    } else {
                        return order === 'asc' ? -1 : 1
                    }
                }
            })
        }

        if(searchDebounceValue) {
            data = data.filter(initiative => initiative.title && initiative.title.toLowerCase().includes(searchValue.toLowerCase())
                   || initiative.owner.fullName && initiative.owner.fullName.toLowerCase().includes(searchValue.toLowerCase()))
        }

        setData(data)
    }, [order, orderBy, searchDebounceValue]);

    const sortHandler = (header: keyof Initiative) => {
        setOrderBy(header);
        setOrder(oldOrder => {
            return oldOrder === 'asc' ? 'desc' : 'asc'
        })
    }

    const onShowAutoComplete = () => {
        setOpenAutoCompleteDialog(true)
    }

    const createNewInitiative = () => {
        navigate('/initiatives/initiative', { state: { okrId } });
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    const onSearchClear = () => {
        setSearchValue('');
    }

    return(
        <TabPanel active={active} vertical>
            <Flexbox className={classes('contentContainer')} vertical>

                <Flexbox className={classes('tableHead')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={onSearchValueChange}
                            onClear={onSearchClear}
                            placeholder='Search Initiative'
                            className={classesInfo('searchInput')}
                        />
                    </Flexbox>
                    {hasPermission(Actions.create) &&
                        <ActionsButton
                            buttonItems={[
                                { label: 'From scratch', action: createNewInitiative, icon: <PlusIcon /> },
                                { label: 'Generate with AI', action: onShowAutoComplete, icon: <GenerateIcon /> },
                            ]}
                            className={classes('actionMenu')}
                            variant='text'
                        />
                    }
                </Flexbox>

                <Flexbox className={classes('tableContainer')}>
                    <Table
                        header={initiativesTableHeader}
                        data={data.map(initiative => {
                            const startDate = initiative.startDate ? new Date(initiative.startDate) : null
                            const releaseDate = initiative.releaseDate ? new Date(initiative.releaseDate) : null
                            return {
                                url: `/initiatives/initiative/${initiative.id}`,
                                data: [
                                    initiative.owner.fullName,
                                    initiative.title,
                                    initiative.products[0]?.title,
                                    <Tooltip title={initiative.priority}>
                                        <Flexbox>
                                            <PriorityIcon priority={initiative.priority} />
                                        </Flexbox>
                                    </Tooltip>,
                                    getEnumKeyByEnumValue(InitiativeStatus, initiative.status),
                                    startDate?.toLocaleDateString(),
                                    releaseDate?.toLocaleDateString(),
                                    <Flexbox>
                                        {initiative.lastModifiedDate &&
                                        <TimeAgo datetime={initiative.lastModifiedDate} live={false} />
                                        }
                                    </Flexbox>,
                                ]
                            }
                        })}
                        orderBy={orderBy}
                        order={order}
                        sortHandler={sortHandler}
                        emptyText={data.length === 0 ? 'There are no Initiative yet' : undefined}
                    />
                </Flexbox>
            </Flexbox>
            <AutocompleteDialog openAutoCompleteDialog={openAutoCompleteDialog} setOpenAutoCompleteDialog={setOpenAutoCompleteDialog}/>
        </TabPanel>
    )
}

export default InitiativesTab