

import classNames from 'classnames/bind';
import styles from '../knowledgeBase.module.scss';
import { Flexbox, MultilineInput } from 'components';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TITLE, currentKbOwnerSelector, currentKbTitleSelector } from 'store/knowledgeBase';
import { editKb } from '../knowledgeBase.api';
import { defaultTitle } from '../knowledgeBase';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);


const Title = () => {
    const titleSelector = useSelector(currentKbTitleSelector);
    const ownerSelector = useSelector(currentKbOwnerSelector);
    const dispatch = useDispatch();

    const [title, setTitle] = useState(titleSelector)
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            if(timeoutId){
                dispatch({ type: UPDATE_TITLE, payload: title })
                dispatch(editKb())
            }
        }, 1000);

        setTimeoutId(newTimeoutId);
    }, [title])

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    return(
        <Flexbox className={classes('infoTitleBox')} fullWidth>
            <MultilineInput className={classes('infoTitleInput')}
                value={title === defaultTitle ? '' : title}
                onChange={onTitleChange}
                placeholder='Create title'
                disabled={!hasPermission(Actions.edit, { owner: ownerSelector })}
                fullWidth
            />
        </Flexbox>

    )
}

export default Title