/* eslint-disable eqeqeq */
// AG-Grid Libs
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
    ColDef,
    ICellRendererParams,
    SideBarDef,
    ISelectCellEditorParams,
    GridReadyEvent,
    ValueGetterParams,
    IDetailCellRendererParams,
    SortChangedEvent,
    StateUpdatedEvent,
    CellEditingStoppedEvent,
    KeyCreatorParams,
    CsvExportParams,
    ExcelExportParams,
    CellEditRequestEvent,
} from 'ag-grid-community';
import { DependencyStatuses, InitiativePriority } from 'utils/types';
import { PriorityIcon, Tooltip, Flexbox, RichTextEditor, AddButton, ProgressBar, Box, PRDScoreBox } from 'components';
import { compareEnumValues } from 'utils/tableSorting';
import ActionsMenu from 'components/ActionsMenu';
import { Link } from 'react-router-dom';
import { ButtonItem } from 'components/ActionsButton';
import { getEnumKeyByEnumValue } from 'utils';
import DependencyStatusIcon from 'pages/Dependencies/components/dependencyStatusIcon';
import './agGrid.scss'
import { Timeout } from 'react-number-format/types/types';
import { LicenseManager } from 'ag-grid-enterprise';
import * as timeago from 'timeago.js';
import SidePanelDrawer from 'components/SidePanelDrawer';
import PRDScoreSidePanel from 'components/PRDScoreSidePanel';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-054405}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Prodmap.ai}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Prodmap.ai}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Prodmap.ai}_need_to_be_licensed___{Prodmap.ai}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_March_2025}____[v3]_[01]_MTc0MDk2MDAwMDAwMA==bdf18b53162cfac65a25c54cd838ffd4');

export interface GridStatePreferences {
    hiddenColIds?: string[],
    groupColIds?: string[],
    aggregationModel?: { colId: string, aggFunc: string }[],
    columnSizingModel?: { key: string, newWidth?: number }[],
    orderedColIds?: string[],
    pivot?: { pivotMode: boolean, pivotColIds: string[] },
}

export enum ColumnTypes {
    Linked,
    SimpleSelect,
    Priority,
    Date,
    Action,
    Chip,
    Circle,
    Progress,
    DependencyStatus,
    TextEditor,
    TimeAgo,
    PRDScore
}
export interface CustomCellRendererParams extends ICellRendererParams {
    isAddActive?: boolean;
    newRow?: any
}
export interface AgColumn extends ColDef {
    colType?: ColumnTypes,
    link?: string,
    linkParam?: string,
    defaultValue?: string,
    actions?: (params: any) => { label: string; action: () => void; type?: string; }[] | ButtonItem[],
    selectorName?: string,
}

interface AgGridTableProps<T = Record<string, any>> extends AgGridReactProps {
    data: T[],
    columns: AgColumn[],
    order?: 'asc' | 'desc' | undefined,
    orderBy?: string,
    onSortChanged?: (e: SortChangedEvent) => void,
    onGridStateChanged?: (data: GridStatePreferences) => void
    gridStatePreferences?: GridStatePreferences,
    pinnedTopRowData?: T[],
    onAddNewRow?: (newRow: any) => void
    onCellEditingStopped?: (params: CellEditingStoppedEvent) => void
    exportFileName?: string,
    updateRowCallback?: (data: any, updateState?: boolean, field?: string) => Promise<boolean | undefined>,
    modifyUpdatedData?: (event: CellEditRequestEvent) => void,
    updateAddingRow?: (data: any) => void,
    showSidebar?: boolean,
}

let debounceTimer: Timeout;

const debounce = (func: () => void, delay: number) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
};

function hasOnlyOneElementWithValue(arr: any[], targetValue: any): boolean {
    return arr.length === 1 && arr[0] === targetValue;
}

const stateUpdatePreventionTriggers = ['gridInitializing', 'scroll', 'focusedCell', 'rowGroupExpansion', 'rowSelection', 'rangeSelection', 'sort']

const AgGridTable: FC<AgGridTableProps> = ({
    data,
    columns,
    rowDragManaged,
    suppressMoveWhenRowDragging,
    rowSelection,
    rowGroupPanelShow,
    pivotPanelShow,
    copyHeadersToClipboard,
    allowContextMenuWithControlKey,
    getContextMenuItems,
    masterDetail,
    isRowMaster,
    detailCellRendererParams,
    order,
    orderBy,
    onSortChanged,
    onGridStateChanged,
    gridStatePreferences,
    pinnedTopRowData,
    onAddNewRow,
    onCellEditingStopped,
    onGridReady,
    exportFileName,
    readOnlyEdit,
    updateRowCallback,
    modifyUpdatedData,
    updateAddingRow,
    domLayout,
    showSidebar = true,
    getRowId
}) => {
    const initiativePriorityEntries = useMemo(() => { return Object.keys(InitiativePriority) }, [])

    const setColumnByType = useCallback((col: AgColumn) => {
        const {
            field,
            actions,
            defaultValue,
            colType,
            link,
            linkParam,
            selectorName,
            ...cellValues
        } = col

        const defaultCell: ColDef = {
            ...cellValues,
            field,
            menuTabs: [],
        }

        const linkedCell = {
            ...defaultCell,
            cellRenderer: (params: ICellRendererParams) => {
                const isGrouped = params.node.group;
                const isPivoted = params.node.leafGroup;

                if (!isGrouped && !isPivoted) {
                    return (
                        <Link
                            className='linked-cell'
                            to={
                                linkParam ? `${link}/${params.data[linkParam]}` : `${link}`
                            }
                        >
                            {typeof params.value === 'object' && selectorName ? params.value[selectorName] : params.value || defaultValue}
                        </Link>
                    );
                } else {
                    return <span>{typeof params.value === 'object' ? params.value?.value : params.value}</span>;
                }

            },
            cellClass: 'ag-custom-cell ag-title',
            lockVisible: true
        }

        const circleCell = {
            ...defaultCell,
            valueGetter: (params: ValueGetterParams) => {
                return params.data && col.field && Array.isArray(params.data[col.field]) ? params.data[col.field]?.map((el: { name: string, id: number }) => el.name) : null;
            },
            cellEditor: 'agSelectCellEditor',
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const isGrouped = params.node.group;
                const isPivoted = params.node.leafGroup;
                if (!isGrouped && !isPivoted) {
                    const fieldName = params.colDef?.field
                    return (
                        <Flexbox wrap className='itemBox'>
                            {
                                fieldName && params?.data[fieldName]?.map((el: { name: string, id: number }, index: number) => (
                                    <Tooltip title={el.name} key={`${el.id}-${index}`}>
                                        <Flexbox className={'circleItem'}>{el.name.slice(0, 1).toUpperCase()}</Flexbox>
                                    </Tooltip>
                                ))
                            }
                        </Flexbox>
                    );
                } else {
                    return <span>{params.value?.length > 0 ? params.value.join(', ') : params.value?.length === 0 ? '(Blanks)' : ''}</span>;
                }
            },
            minWidth: 60
        }

        const chipCell = {
            ...defaultCell,
            valueGetter: (params: ValueGetterParams) => {
                const name = selectorName || 'title'
                return params.data && col.field && Array.isArray(params.data[col.field]) ? params.data[col.field].map((el: any) => el[name]) : null;
            },
            cellEditor: 'agSelectCellEditor',
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell chip-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const isGrouped = params.node.group;
                const isPivoted = params.node.leafGroup;

                if (!isGrouped && !isPivoted) {
                    const fieldName = col.field
                    return (
                        <Flexbox wrap className='itemBox'>
                            {
                                fieldName && params.data[fieldName].map((el: any) => <Flexbox className={'chipItem'} key={`${el.id}-${el.name}`}>{selectorName ? el[selectorName] : el.title}</Flexbox>)
                            }
                        </Flexbox>
                    );
                } else {
                    return <span>{params.value?.length > 0 ? params.value.join(', ') : params.value?.length === 0 ? '(Blanks)' : ''}</span>;
                }
            },
            minWidth: 100
        }

        const priorityCell = {
            ...defaultCell,
            cellEditorParams: {
                values: initiativePriorityEntries,
            } as ISelectCellEditorParams,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group && params.node.field !== field) {
                    return null;
                } else {
                    return (
                        <Tooltip title={params.value}>
                            <div><PriorityIcon priority={params.value} /></div>
                        </Tooltip>
                    )
                }
            },
            cellEditor: 'agSelectCellEditor',
            comparator: (d1: any, d2: any) => {
                return compareEnumValues(d1, d2, InitiativePriority)
            },
            filterParams: {
                ...defaultCell.filterParams,
                values: initiativePriorityEntries,
            },
            minWidth: 50
        }

        const simpleSelectCell = {
            ...defaultCell,
            cellEditor: 'agSelectCellEditor',
            filterParams: {
                ...defaultCell.filterParams,
                ...col.filterParams,
            }
        }

        const dateCell = {
            ...defaultCell,
            cellEditor: 'agDateCellEditor',
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (!params.value) {
                    return '';
                }

                let value = params.value;

                if (typeof value === 'string') {
                    const numberValue = Number(value);

                    if (!isNaN(numberValue)) {
                        value = numberValue;
                    } else {
                        const date = new Date(value);
                        if (!isNaN(date.getTime())) {
                            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
                            return date.toLocaleDateString('en-US', options);
                        }
                    }
                }

                if (typeof value === 'number' && !isNaN(new Date(value).getTime())) {
                    const date = new Date(value);
                    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
                    return date.toLocaleDateString('en-US', options);
                }

                return value;
            },
            keyCreator: (params: KeyCreatorParams) => {
                return params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
            }
        }

        const progressCell = {
            ...defaultCell,
            cellRenderer: (params: ICellRendererParams) => {
                return params.node.group && params.node.field !== 'progress' ? null : (
                    params.value === null ? 'N/A' : (
                        <Flexbox align className={'h-full'}>
                            <ProgressBar value={params.value} />
                        </Flexbox>
                    )
                )
            },
            comparator: (a: number | null, b: number | null) => {
                if (a === null && b === null) {
                    return 0;
                }
                if (a === null) {
                    return -1;
                }
                if (b === null) {
                    return 1;
                }

                if (a === 0 && b === 0) {
                    return 0;
                }
                if (a === 0) {
                    return -1;
                }
                if (b === 0) {
                    return 1;
                }

                return a - b;
            }
        }

        const textEditorCell = {
            ...defaultCell,
            minWidth: 250,
            wrapText: true,
            autoHeight: true,
            cellClass: 'editor-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const isGrouped = params.node.group;
                const isPivoted = params.node.leafGroup;

                return (
                    <Flexbox align>
                        {
                            (isGrouped || isPivoted) ? <div
                                dangerouslySetInnerHTML={{ __html: !!params.value ? params.value : params.value === undefined ? '' : '(Blanks)' }}
                                className='grouped-editor'
                            /> : <RichTextEditor value={params.value} disabled readOnly onChange={() => { }} files={[]} editorMinHeight={45} />
                        }
                    </Flexbox>
                );
            }
        }

        const dependencyStatusCell = {
            ...defaultCell,
            minWidth: 150,
            cellRenderer: (params: ICellRendererParams) => {
                return params.node.group && params.node.field !== col.field ? null : (
                    <Flexbox align>
                        <DependencyStatusIcon status={params.value} />
                        {getEnumKeyByEnumValue(DependencyStatuses, params.value)}
                    </Flexbox>
                )
            },
            comparator: (d1: any, d2: any) => {
                return compareEnumValues(d1, d2, DependencyStatuses)
            },
        }

        const actionCell = {
            ...defaultCell,
            cellRenderer: (params: CustomCellRendererParams) => {
                if (params?.data?.add && onAddNewRow) {
                    return (
                        <AddButton
                            onClick={() => onAddNewRow(params)}
                            active={params.isAddActive}
                        />
                    )
                } else {
                    const items = actions && actions(params) as ButtonItem[]
                    return (!items || items.length === 0) ? null :
                        <ActionsMenu
                            buttonItems={items}
                        />
                }
            },
            sortable: false,
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            enableValue: false,
            enablePivot: false,
            enableRowGroup: false,
            filter: false,
            suppressColumnsToolPanel: true,
            minWidth: 50,
            maxWidth: 50,
            pinned: 'right' as boolean | 'left' | 'right' | null | undefined,
            suppressMovable: true,
            resizable: false,
        }

        const timeAgoCell = {
            ...defaultCell,
            cellRenderer: (params: ICellRendererParams) => {
                return params.value ? timeago.format(params.value) : ''
            },
            keyCreator: (params: KeyCreatorParams) => {
                return timeago.format(params.value)
            }
        }

        const prdScoreCell = {
            ...defaultCell,
            cellClass: 'ag-score-cell',
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== col.field) {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className='h-full'><PRDScoreBox value={params.value} /></Flexbox> : null
                    }
                } else if (params.value === null) {
                    return <Box sx={{ paddingLeft: '8px' }}>N/A</Box>
                } else {
                    return (
                        <SidePanelDrawer
                            actionElement={
                                (props: any) => (
                                    <Flexbox align className={'h-full'} {...props}>
                                        <PRDScoreBox value={params.value} />
                                    </Flexbox>
                                )
                            }
                        >
                            {
                                <PRDScoreSidePanel
                                    initiativeId={params.data.id}
                                />
                            }
                        </SidePanelDrawer>
                    );
                }
            },
        }

        switch (colType) {
            case ColumnTypes.Linked:
                return linkedCell
            case ColumnTypes.SimpleSelect:
                return simpleSelectCell
            case ColumnTypes.Date:
                return dateCell
            case ColumnTypes.Priority:
                return priorityCell
            case ColumnTypes.Action:
                return actionCell
            case ColumnTypes.Chip:
                return chipCell
            case ColumnTypes.Circle:
                return circleCell
            case ColumnTypes.Progress:
                return progressCell
            case ColumnTypes.DependencyStatus:
                return dependencyStatusCell
            case ColumnTypes.TextEditor:
                return textEditorCell
            case ColumnTypes.TimeAgo:
                return timeAgoCell
            case ColumnTypes.PRDScore:
                return prdScoreCell
            default:
                return defaultCell
        }
    }, [])

    const colDefs: ColDef[] = columns.map((col: AgColumn) => setColumnByType(col))

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(colDefs)
    const [gridData, setGridData] = useState<any>()

    // _______________________DATA_______________________________________
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            enableRowGroup: true,
            enablePivot: true,
            filter: false,
            defaultAggFunc: 'first',
            editable: false,
            rowDrag: false,
            minWidth: 80,
            headerCheckboxSelection: false,
            checkboxSelection: false,
            sortable: false,
            enableValue: true,
            showRowGroup: false,
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['reset'],
            },
            comparator: (a, b) => typeof a === 'string' ? a?.toLowerCase().localeCompare(b?.toLowerCase()) : (a > b ? 1 : (a < b ? -1 : 0)),
            floatingFilter: false,
        };
    }, []);

    const defaultCsvExportParams = useMemo<CsvExportParams>(() => {
        return {
            fileName: `${exportFileName || 'export'}.csv`,
        };
    }, []);

    const defaultExcelExportParams = useMemo<ExcelExportParams>(() => {
        return {
            fileName: `${exportFileName || 'export'}.xlsx`,
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['columns'],
            defaultToolPanel: '',
        };
    }, []);

    const nestedParams = useCallback((masterGridParams: IDetailCellRendererParams) => {
        return {
            ...detailCellRendererParams,
            detailGridOptions: {
                ...detailCellRendererParams.detailGridOptions,
                columnDefs: detailCellRendererParams.detailGridOptions.columnDefs.map((col: AgColumn) => setColumnByType(col)),
                context: {
                    masterGrid: {
                        node: masterGridParams.node.parent,
                        data: masterGridParams.data
                    }
                },
                getRowId,
                onCellEditRequest,
                readOnlyEdit
            },
        }
    }, [detailCellRendererParams])

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            minWidth: 200,
        };
    }, []);

    // _______________________MAIN_FUNCTIONS_______________________________________
    const handleGridReady = (e: GridReadyEvent) => {
        const { api } = e

        if (order && orderBy) {
            api.applyColumnState({
                state: [
                    {
                        colId: orderBy,
                        sort: order
                    }
                ],
            })
        }

        if (gridStatePreferences) {
            const {
                hiddenColIds,
                groupColIds,
                aggregationModel,
                columnSizingModel,
                orderedColIds,
                pivot,
            } = gridStatePreferences

            if (hiddenColIds) {
                api.setColumnsVisible(hiddenColIds, false)
            }
            if (pivot?.pivotMode) {
                api.setGridOption('pivotMode', pivot.pivotMode)
                api.setPivotColumns(pivot.pivotColIds)
            }
            if (groupColIds) {
                api.setRowGroupColumns(groupColIds)
            }
            if (aggregationModel) {
                api.setGridOption('columnDefs', columnDefs.map(col => {
                    const aggCol = aggregationModel.find(el => el.colId === col.field)
                    if (aggCol) {
                        return {
                            ...col,
                            aggFunc: aggCol.aggFunc
                        }
                    } else {
                        return col
                    }
                }))
            }
            if (columnSizingModel) {
                const sizes = columnSizingModel.filter(el => typeof el.newWidth === 'number')
                api.setColumnWidths(sizes as { key: string, newWidth: number }[])
            }
            if (orderedColIds) {
                orderedColIds.forEach((col, index) => api?.moveColumn(col, index));
            }
        }

        api.setGridOption('headerHeight', undefined)

        onGridReady && onGridReady(e)
    }

    const onStateUpdated = (data: StateUpdatedEvent) => {
        if (onGridStateChanged && !stateUpdatePreventionTriggers.some(state => hasOnlyOneElementWithValue(data.sources, state))) {
            const gridUpdatedState = {
                hiddenColIds: data.state.columnVisibility?.hiddenColIds || [],
                aggregationModel: data.state.aggregation?.aggregationModel || [],
                groupColIds: data.state.rowGroup?.groupColIds || [],
                columnSizingModel: data.state.columnSizing?.columnSizingModel.filter(el => !!el.width).map(el => ({ newWidth: el.width, key: el.colId })) || [],
                orderedColIds: data.state.columnOrder?.orderedColIds || [],
                pivot: data.state.pivot || { pivotMode: false, pivotColIds: [] },
            }

            debounce(() => {
                onGridStateChanged(gridUpdatedState);
            }, 500)
        }
    }

    const onCellEditRequest = useCallback(async (event: CellEditRequestEvent) => {
        const oldData = event.data;
        const field = event.colDef.field;

        const newData = { ...oldData };
        newData[field!] = modifyUpdatedData ? modifyUpdatedData(event) : event.newValue;

        if (!event.data.id && updateAddingRow) {
            updateAddingRow(newData)
        } else {
            const tx = {
                update: [newData],
            };

            let shouldUpdate: boolean | undefined = true

            if (updateRowCallback) {
                shouldUpdate = updateRowCallback && await updateRowCallback(newData, false, field)
            }

            shouldUpdate && event.api.applyTransaction(tx);
        }
    }, [updateRowCallback]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setGridData(data);
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [data]);

    useEffect(() => {
        setColumnDefs(colDefs)
    }, [columns])

    return (
        <div className={'ag-theme-quartz agGrid-container'}>
            <AgGridReact
                rowData={gridData}
                className='agGrid-table'
                columnDefs={columnDefs}
                rowDragManaged={rowDragManaged ?? false}
                suppressMoveWhenRowDragging={suppressMoveWhenRowDragging ?? true}
                defaultColDef={defaultColDef}
                rowDragEntireRow={false}
                rowSelection={rowSelection ?? 'multiple'}
                rowGroupPanelShow={rowGroupPanelShow ?? 'always'}
                pivotPanelShow={pivotPanelShow ?? 'always'}
                copyHeadersToClipboard={copyHeadersToClipboard ?? false}
                sideBar={showSidebar ? sideBar : false}
                allowContextMenuWithControlKey={allowContextMenuWithControlKey ?? true}
                getContextMenuItems={getContextMenuItems}
                onSortChanged={onSortChanged}
                onGridReady={handleGridReady}
                isRowMaster={isRowMaster}
                masterDetail={masterDetail}
                detailCellRendererParams={nestedParams}
                alwaysShowHorizontalScroll
                alwaysShowVerticalScroll
                autoGroupColumnDef={autoGroupColumnDef}
                detailRowAutoHeight
                onStateUpdated={onStateUpdated}
                pinnedTopRowData={pinnedTopRowData}
                onCellEditingStopped={onCellEditingStopped}
                suppressBrowserResizeObserver
                headerHeight={0}
                animateRows={false}
                autoSizeStrategy={gridStatePreferences ? undefined : { type: 'fitGridWidth' }}
                suppressScrollOnNewData
                suppressAnimationFrame
                defaultCsvExportParams={defaultCsvExportParams}
                defaultExcelExportParams={defaultExcelExportParams}
                suppressNoRowsOverlay={!!data.length}
                readOnlyEdit={readOnlyEdit}
                getRowId={getRowId}
                onCellEditRequest={onCellEditRequest}
                domLayout={domLayout || 'normal'}
                onColumnRowGroupChanged={(e) => e.api.setColumnVisible('actions', !e.columns?.some(col => col.isRowGroupActive()))}
            />
        </div>
    );
};

export default AgGridTable