import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { logout, refreshToken } from 'pages/Home/home.api';
import { io } from 'socket.io-client';
import store from 'store';
import { ACTION_SET_GLOBAL_ERROR, ACTION_UPDATE_NOT_FOUND } from 'store/globalError';
import { AIRTABLE_API_KEY, AIRTABLE_BASE_URL, AIStreamingEndCharCode, ASSISTANT_URL, BASE_URL, COGNITO_OAUTH_CLIENT_ID, COGNITO_OAUTH_DOMAIN, MICROSOFT_OAUTH_CLIENT_ID, MICROSOFT_OAUTH_DOMAIN, OAUTH_REDIRECT_URI } from 'utils/constants';
import { SsoProviderType } from './types';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 1000 * 45, // 45 sec
    // withCredentials: true,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus: (status) => status < 400,
    transformRequest: (data, headers) => {
        const token = store.getState().user.token;
        if (token && headers && !headers['Authorization']) {
            headers['Authorization'] = 'Bearer ' + token
        }

        if (headers && headers['Content-Type'] === 'multipart/form-data') {
            return data
        }
        return JSON.stringify(data);
    },


});

axiosRetry(axiosInstance, {
    retries: 1,
    retryCondition: (error: AxiosError) => {
        console.log('retry condition');

        return !!error.response && error.response.status === 401;
    },
    onRetry: async (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {
        try {

            await refreshToken();
            const token = store.getState().user.token;

            if (token && requestConfig.headers && requestConfig.headers['Authorization']) {
                requestConfig.headers['Authorization'] = 'Bearer ' + token

            }
        } catch (e) {
            await logout();
            throw e;
        }
    }
});


class Request {
    errorHandler = async (error: AxiosError) => {
        if (error.code === 'ECONNABORTED') {
            const timeoutMessage = `Request timed out. Please refresh and try again. (URL: ${error.config?.url})`;

            store.dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: timeoutMessage });
        }

        if (error.message.toLowerCase().includes('network error')) {
            store.dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: error.message });
        }

        if ((error.response?.data && typeof error.response.data === 'object' &&
            (
                ('code' in error.response.data && error.response.data.code === 'access_denied') ||
                ('status' in error.response && error.response.status === 403)
            ))
            || error.code === 'entity_not_found'
        ) {
            store.dispatch({ type: ACTION_UPDATE_NOT_FOUND, payload: true });
        }

        throw error.response
    }

    get = (url: string, props: AxiosRequestConfig = {}) => {

        return axiosInstance.get(url, props).catch(this.errorHandler)
    }

    post = (url: string, data?: any, props: AxiosRequestConfig = {}) => {

        return axiosInstance.post(url, data, props).catch(this.errorHandler)
    }

    put = (url: string, data?: any, props: AxiosRequestConfig = {}) => {

        return axiosInstance.put(url, data, props).catch(this.errorHandler);
    }

    delete = (url: string, props: AxiosRequestConfig = {}) => {

        return axiosInstance.delete(url, props).catch(this.errorHandler);
    }

    uploadFile = (url: string, file: File, onUploadProgress: (file: File, progressEvent: any) => void = () => { }) => {
        const formData = new FormData();
        formData.append('file', file);
        return this.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (e) => { onUploadProgress(file, e) }
        })
    }
}

const instance = new Request();

// export const uploadFile = (url: string, file: File, onUploadProgress: (file: File, progressEvent: any) => void = () => {}) => {
//     const formData = new FormData();
//     formData.append('file', file);
//     return instance.post(url, formData, {
//         headers: {
// 		  'Content-Type': 'multipart/form-data',
//         },
//         onUploadProgress: (e) => {onUploadProgress(file, e)}
//     })
// }

export const oAuthLogin = (provider: SsoProviderType) => {
    const domain = COGNITO_OAUTH_DOMAIN;
    const queryString = Object.entries({
        redirect_uri: OAUTH_REDIRECT_URI,
        response_type: 'code',
        client_id: COGNITO_OAUTH_CLIENT_ID,
        identity_provider: provider,
        state: 'cognito',
        scope: ['openid', 'profile', 'email'].join(' '),

    })
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');

    const URL = `https://${domain}/oauth2/authorize?${queryString}`;

    console.log(URL);
    window.open(URL, '_self');
}

export const oAuthMicrosoftLogin = () => {
    const queryString = Object.entries({
        redirect_uri: OAUTH_REDIRECT_URI,
        response_type: 'code',
        client_id: MICROSOFT_OAUTH_CLIENT_ID,
        response_mode: 'query',
        state: 'Microsoft',
        scope: ['openid', 'profile', 'https://graph.microsoft.com/user.read', 'offline_access'].join(' '),

    })
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');

    const URL = `${MICROSOFT_OAUTH_DOMAIN}/oauth2/v2.0/authorize?${queryString}`;

    console.log(URL);
    window.open(URL, '_self');
}

export default instance;

export const createQueryParamsFromObject = (data: Record<string, string>) => {
    const searchParams = new URLSearchParams(data);
    return searchParams.toString();
}


const socket = io(ASSISTANT_URL, {
    autoConnect: true,
    transports: ['websocket', 'polling'],
});
function onDisconnect() {
    socket.off('disconnect', onDisconnect);
    socket.off('connect_error', onErrorMessage);
}


function onErrorMessage(error: Error) {
    console.log(error);
    socket.disconnect();
}

socket.on('disconnect', onDisconnect);
socket.on('connect_error', onErrorMessage);

interface SocketError extends Error {
    code: number;
}

export const emit = (action: string, props: { [key: string]: any }, callback: (message: string) => void, onError: (error: Error) => void) => {

    return new Promise<void>((resolve, reject) => {
        if (socket.connected) {

            function onMessage(value: string) {
                callback(value)
                if (value.charCodeAt(0) === AIStreamingEndCharCode) {
                    socket.off('response', onMessage);
                    socket.off('error', errorHandler);
                    resolve();
                }
            }

            async function errorHandler(error: SocketError) {
                if (error.code && error.code === 401) {
                    // refresh token
                    await refreshToken();
                    const token = store.getState().user.token;
                    if (token && props && props['Authorization']) {
                        props['Authorization'] = 'Bearer ' + token
                    }
                    socket.emit(action, props);
                } else {
                    onError(error);
                    socket.off('response', onMessage);
                    socket.off('error', errorHandler);
                    reject(error);
                }
            }

            socket.on('response', onMessage);
            socket.on('error', errorHandler);
            socket.emit(action, props);
        } else {
            reject(new Error('Socket is not connected'));
        }
    })
}

export const sendDataToAirtable = (data: object, tableName: string) => {

    try {
        axios.post(`${AIRTABLE_BASE_URL}/${tableName}`, {
            fields: data
        }, {
            headers: {
                'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
                'Content-Type': 'application/json',
            }
        });
    } catch (error) {
        console.error('Error sending data to Airtable:', error);
    }
}