const EmptyKnowledgeBase = () => {
    return (
        <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M144.984 27.0703H167.625V153.703H144.984V27.0703ZM139.641 153.633C139.641 156.727 141.891 158.977 144.984 158.977H167.625C170.719 158.977 172.969 156.727 172.969 153.633V27.0703C172.969 23.9766 172.055 23.0625 168.961 23.0625H143.648C140.555 23.0625 139.641 23.9766 139.641 27.0703V153.633Z" fill="#EBEBEB"/>
            <path d="M110.32 27.0703H132.961V153.703H110.32V27.0703ZM104.977 153.633C104.977 156.727 107.227 158.977 110.32 158.977H132.961C136.055 158.977 138.305 156.727 138.305 153.633V27.0703C138.305 23.9766 137.391 23.0625 134.297 23.0625H108.984C105.891 23.0625 104.977 23.9766 104.977 27.0703V153.633Z" fill="#EBEBEB"/>
            <path d="M75.0234 27.0703H97.6641V153.703H75.0234V27.0703ZM69.6797 153.633C69.6797 156.727 71.9297 158.977 75.0234 158.977H97.6641C100.758 158.977 103.008 156.727 103.008 153.633V27.0703C103.008 23.9766 102.094 23.0625 99 23.0625H73.6875C70.5938 23.0625 69.6797 23.9766 69.6797 27.0703V153.633Z" fill="#EBEBEB"/>
            <path d="M79.6641 63.0703C79.6641 64.8984 81.4922 65.7422 83.6719 65.7422H89.0156C91.1953 65.7422 93.0234 64.9687 93.0234 63.0703C93.0234 61.2422 91.1953 60.3984 89.0156 60.3984H83.6719C81.4922 60.3984 79.6641 61.1719 79.6641 63.0703Z" fill="#EBEBEB"/>
            <path d="M79.6641 76.3594C79.6641 78.1875 81.4922 79.0312 83.6719 79.0312H89.0156C91.1953 79.0312 93.0234 78.2578 93.0234 76.3594C93.0234 74.5312 91.1953 73.6875 89.0156 73.6875H83.6719C81.4922 73.6875 79.6641 74.4609 79.6641 76.3594Z" fill="#EBEBEB"/>
            <path d="M79.6641 89.0156C79.6641 90.8437 81.4922 91.6875 83.6719 91.6875H89.0156C91.1953 91.6875 93.0234 90.9141 93.0234 89.0156C93.0234 87.1875 91.1953 86.3438 89.0156 86.3438H83.6719C81.4922 86.3438 79.6641 87.1875 79.6641 89.0156Z" fill="#EBEBEB"/>
            <path d="M79.6641 49.7109C79.6641 51.5391 81.4922 52.3828 83.6719 52.3828H89.0156C91.1953 52.3828 93.0234 51.6094 93.0234 49.7109C93.0234 47.8828 91.1953 47.0391 89.0156 47.0391H83.6719C81.4922 47.0391 79.6641 47.8125 79.6641 49.7109Z" fill="#EBEBEB"/>
            <path d="M45.9122 25.5235L67.7794 31.5001L34.2404 153.633L12.3732 147.656L45.9122 25.5235ZM7.24036 146.25C6.39661 149.273 7.94349 151.945 10.9669 152.789L32.8341 158.766C35.8575 159.609 38.5294 158.063 39.3732 155.039L72.8419 32.9063C73.6857 29.9532 72.9825 28.8282 70.0294 27.9844L45.631 21.3047C42.6779 20.461 41.5529 21.1641 40.7091 24.1172L7.24036 146.25Z" fill="#EBEBEB"/>
            <path d="M40.8497 61.4528C40.3575 63.2809 41.9044 64.4762 44.0138 65.109L49.1466 66.5153C51.256 67.0778 53.2247 66.7965 53.7169 65.0387C54.2091 63.2106 52.6622 62.0153 50.5528 61.3824L45.42 59.9762C43.3106 59.4137 41.3419 59.6949 40.8497 61.4528Z" fill="#EBEBEB"/>
            <path d="M37.3341 74.3197C36.8419 76.1478 38.3888 77.3432 40.4981 77.976L45.631 79.3822C47.7403 79.9447 49.7091 79.6635 50.2013 77.9057C50.6935 76.1478 49.1466 74.8822 47.0372 74.2494L41.9044 72.8432C39.795 72.2103 37.8263 72.5619 37.3341 74.3197Z" fill="#EBEBEB"/>
            <path d="M33.9591 86.5562C33.4669 88.3843 35.0138 89.5796 37.1231 90.2124L42.2559 91.6187C44.3653 92.1812 46.3341 91.8999 46.8263 90.1421C47.3184 88.314 45.7716 87.1187 43.6622 86.4858L38.5294 85.0796C36.42 84.4468 34.4513 84.728 33.9591 86.5562Z" fill="#EBEBEB"/>
            <path d="M44.3653 48.6577C43.8731 50.4858 45.42 51.6812 47.5294 52.314L52.6622 53.7202C54.7716 54.2827 56.7403 54.0015 57.2325 52.2437C57.7247 50.4155 56.1778 49.2202 54.0685 48.5874L48.9356 47.1812C46.8263 46.5483 44.8575 46.8296 44.3653 48.6577Z" fill="#EBEBEB"/>
            <path d="M115.031 63.0703C115.031 64.8984 116.859 65.7422 119.039 65.7422H124.383C126.562 65.7422 128.391 64.9687 128.391 63.0703C128.391 61.2422 126.562 60.3984 124.383 60.3984H119.039C116.789 60.3984 115.031 61.1719 115.031 63.0703Z" fill="#EBEBEB"/>
            <path d="M115.031 76.3594C115.031 78.1875 116.859 79.0312 119.039 79.0312H124.383C126.562 79.0312 128.391 78.2578 128.391 76.3594C128.391 74.5312 126.562 73.6875 124.383 73.6875H119.039C116.789 73.6875 115.031 74.4609 115.031 76.3594Z" fill="#EBEBEB"/>
            <path d="M115.031 89.0156C115.031 90.8437 116.859 91.6875 119.039 91.6875H124.383C126.562 91.6875 128.391 90.9141 128.391 89.0156C128.391 87.1875 126.562 86.3438 124.383 86.3438H119.039C116.789 86.3438 115.031 87.1875 115.031 89.0156Z" fill="#EBEBEB"/>
            <path d="M115.031 49.7109C115.031 51.5391 116.859 52.3828 119.039 52.3828H124.383C126.562 52.3828 128.391 51.6094 128.391 49.7109C128.391 47.8828 126.562 47.0391 124.383 47.0391H119.039C116.789 47.0391 115.031 47.8125 115.031 49.7109Z" fill="#EBEBEB"/>
            <path d="M149.625 63.0703C149.625 64.8984 151.453 65.7422 153.633 65.7422H158.977C161.156 65.7422 162.984 64.9687 162.984 63.0703C162.984 61.2422 161.156 60.3984 158.977 60.3984H153.633C151.453 60.3984 149.625 61.1719 149.625 63.0703Z" fill="#EBEBEB"/>
            <path d="M149.625 76.3594C149.625 78.1875 151.453 79.0312 153.633 79.0312H158.977C161.156 79.0312 162.984 78.2578 162.984 76.3594C162.984 74.5312 161.156 73.6875 158.977 73.6875H153.633C151.453 73.6875 149.625 74.4609 149.625 76.3594Z" fill="#EBEBEB"/>
            <path d="M149.625 89.0156C149.625 90.8437 151.453 91.6875 153.633 91.6875H158.977C161.156 91.6875 162.984 90.9141 162.984 89.0156C162.984 87.1875 161.156 86.3438 158.977 86.3438H153.633C151.453 86.3438 149.625 87.1875 149.625 89.0156Z" fill="#EBEBEB"/>
            <path d="M149.625 49.7109C149.625 51.5391 151.453 52.3828 153.633 52.3828H158.977C161.156 52.3828 162.984 51.6094 162.984 49.7109C162.984 47.8828 161.156 47.0391 158.977 47.0391H153.633C151.453 47.0391 149.625 47.8125 149.625 49.7109Z" fill="#EBEBEB"/>
        </svg>
    )
}

export default EmptyKnowledgeBase;