import { Box, Drawer, SxProps, Theme, styled } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

const DRAWER_WIDTH = 350;

const Container = styled('div', { shouldForwardProp: prop => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -DRAWER_WIDTH,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
}));

interface SidebarContainerProps {
    sx?: SxProps<Theme>;
    open?: boolean;
    drawerContent?: ReactNode;
}

export const SidebarContainer: FC<PropsWithChildren<SidebarContainerProps>> = ({
    sx,
    open,
    children,
    drawerContent,
}) => {
    return (
        <Box sx={{ ...sx, display: 'flex', width: '100%' }}>
            <Container open={open}>{children}</Container>
            <Drawer
                sx={{
                    'width': DRAWER_WIDTH,
                    'flexShrink': 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                {drawerContent}
            </Drawer>
        </Box>
    );
};
