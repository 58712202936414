import classNames from 'classnames/bind';
import styles from './styles.module.scss'
import { Flexbox, Loader, ProgressBar, CustomTyphography, CustomSnackbar, RiskStatusBox } from 'components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridApi } from 'ag-grid-enterprise';
import { GitBranch, Plus } from 'components/icons';
import { Actions, hasPermission } from 'utils/permissions';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable';
import { GetRowIdFunc, GetRowIdParams, GridReadyEvent, ICellRendererParams, KeyCreatorParams, SortChangedEvent } from 'ag-grid-community';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEvaluateNodeRiskMutation, useLazyGetViewQuery, useLazyGetViewNodesQuery } from 'store/views-api';
import { useWorkspaceId } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { nodesSelector, viewSelector, viewErrorSelector, evaluateNodeRiskErrorSelector, setEvaluateNodeRiskError, updateNodeRiskAction } from 'store/views-slice';
import LinkedNodesModal from 'components/LinkedNodesModal';
import LinkNodeModal from 'components/LinkNodeModal';
import { LinkNode, Node, Preferences, PreferencesKeys } from 'utils/types';
import SidePanelDrawer from 'components/SidePanelDrawer';
import AlertsSidePanel from 'components/AlertsSidePanel';
import ViewHeader from './components/ViewHeader';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';

const classes = classNames.bind(styles);

function getTextAfterDot(text: string) {
    return text.startsWith('data.') ? text.substring(text.indexOf('.') + 1) : text
}

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    executionView = 'executionView',
}

export interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof Node,
    executionView?: any,
}

interface IPreferencesParams extends Omit<IPreferencesData, 'executionView'> { }

let timeoutId: NodeJS.Timeout;

const View = () => {
    const params = useParams()
    const workspaceId = useWorkspaceId()
    const dispatch = useDispatch()
    const viewId = params['id'] ? parseInt(params['id']) : undefined;

    const preferencesRef = useRef<IPreferencesData>({});

    const [searchParams, setSearchParams] = useSearchParams();
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [openLinkingNodesModal, setOpenLinkingNodesModal] = useState<boolean>(false)
    const [openLinkToNodesModal, setOpenLinkToNodesModal] = useState<boolean>(false)
    const [currentNode, setCurrentNode] = useState<{ nodeId: number, nodeTitle: string, linkNodes: LinkNode[] } | null>(null)

    const [getView, { isLoading: getViewLoading }] = useLazyGetViewQuery();
    const [evaluateNodeRisk] = useEvaluateNodeRiskMutation();
    const [getViewNodes, { isLoading: viewNodesLoading, data: nodesData }] = useLazyGetViewNodesQuery()
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);
    const [searchValue, setSearchValue] = useState('');
    const [evaluateSuccessMessage, setEvaluateSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [evaluatingNodes, setEvaluatingNodes] = useState<number[]>([])

    const view = useSelector(viewSelector)
    const nodes = useSelector(nodesSelector)
    const viewError = useSelector(viewErrorSelector)
    const evaluateNodeRiskError = useSelector(evaluateNodeRiskErrorSelector)

    const [filteredNodes, setFilteredNodes] = useState(nodes)

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.executionView))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const { executionView, order, orderBy, query } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            preferencesRef.current = {
                query,
                order,
                orderBy,
                executionView
            }

            if (searchParams.toString().length === 0) {
                let queryFilters: IPreferencesParams = {}

                queryFilters = {
                    ...queryFilters,
                    ...(order && orderBy ? { order, orderBy } : {}),
                    ...(query ? { query } : {})
                };

                setSearchParams(queryFilters, { replace: true })
            }
        }
    }

    const handleEvaluateNodeRisk = async (id: number) => {
        try {
            setEvaluatingNodes(prev => ([...prev, id]))
            const res = await evaluateNodeRisk({
                workspaceId,
                viewId,
                nodeId: id,
            }).unwrap();

            const currentRow = nodes.find(node => node.id === id)

            const transaction = {
                update: [{
                    ...currentRow,
                    risk: res
                }],
            };

            if (gridApi) {
                gridApi.applyTransactionAsync(transaction);
                dispatch(updateNodeRiskAction({ nodeId: id, risk: res }))
            }

            setEvaluateSuccessMessage('Risk Evaluated Successfully')
            setTimeout(() => setEvaluateSuccessMessage(''), 3000)
        } catch (error) {
            console.error('Failed to evaluate', error);
            setTimeout(() => dispatch(setEvaluateNodeRiskError('')), 3000)
        } finally {
            setEvaluatingNodes(prev => prev.filter(el => el !== id))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                loadPreferences()
            ])
            setIsLoading(false)
        }
        setIsLoading(true);
        fetchData();

        return () => {
            dispatch(setEvaluateNodeRiskError(''))
            setEvaluateSuccessMessage('')
            resetCurrentNode()
            setEvaluatingNodes([])
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [isLoading])

    useEffect(() => {
        getView({ workspaceId, viewId })
        getViewNodes({ workspaceId, viewId })
    }, [viewId, workspaceId])

    const columns: AgColumn[] = useMemo(() => [
        // Real one
        {
            headerName: 'Initiative',
            field: 'data.title',
            minWidth: 350,
            sortable: true,
            pinned: 'left' as boolean | 'left' | 'right' | null | undefined,
            suppressMovable: true,
            wrapText: true,
            autoHeight: true,
            cellRenderer: (params: ICellRendererParams) => {
                return <Flexbox vertical className={classes('initiativeCell')}>
                    <CustomTyphography type='primary'>
                        {params.value}
                    </CustomTyphography>
                </Flexbox>
            }
        },
        {
            headerName: 'Sources',
            field: 'linkNodes',
            minWidth: 150,
            sortable: true,
            enableRowGroup: false,
            cellRenderer: (params: ICellRendererParams) => {
                return params.node.group && params.node.field !== 'linkNodes' ? null :
                    <Flexbox wrap align className={classes('sourcesContainer')}>

                        {(!params.value || !params.value.length) ? (
                            <Flexbox justify
                                align
                                className={classes('sourcesBox', 'bg-surface', { 'cursor-pointer': hasPermission(Actions.edit) })}
                                onClick={() => hasPermission(Actions.edit) && handleOpenLinkToNodesModal({ linkNodes: params.value, nodeId: params.data.id, nodeTitle: params.data.data.title })}
                            >
                                <GitBranch />
                                <CustomTyphography className={classes('font-500')}>Link Items</CustomTyphography>
                            </Flexbox>
                        ) : (
                            <Flexbox align justify className={classes('gap-1')}>
                                <Flexbox
                                    justify
                                    align
                                    className={classes('sourcesBox', 'bg-surface', 'cursor-pointer')}
                                    onClick={() => {
                                        if (Array.isArray(params.value) && params.value.length) {
                                            handleOpenLinkingNodesModal({ linkNodes: params.value, nodeId: params.data.id, nodeTitle: params.data.data.title })
                                        }
                                    }}
                                >
                                    <CustomTyphography type='primary'>
                                        {params.value.length} {params.value.length === 1 ? 'Link' : 'Links'}
                                    </CustomTyphography>
                                </Flexbox>
                                <Flexbox justify
                                    align
                                    className={classes('sourcesBox', 'bg-surface', 'color-primaryTextColor', { 'cursor-pointer': hasPermission(Actions.edit) })}
                                    onClick={() => hasPermission(Actions.edit) && handleOpenLinkToNodesModal({ linkNodes: params.value, nodeId: params.data.id, nodeTitle: params.data.data.title })}
                                >
                                    <Plus />
                                </Flexbox>
                            </Flexbox>
                        )
                        }
                    </Flexbox >
            }
        },
        {
            headerName: 'Progress',
            field: 'progress',
            minWidth: 200,
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                return params.node.group && params.node.field !== 'progress' ? null : (
                    params.value === null ? null : (
                        <Flexbox align className={classes('h-full')}>
                            <ProgressBar value={params.value} />
                        </Flexbox>
                    )
                )
            }
        },
        {
            headerName: 'Health',
            field: 'risk',
            minWidth: 130,
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'risk') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className={classes('h-full')}>
                            <RiskStatusBox category={value?.category} withoutAlerts />
                        </Flexbox> : null
                    }
                } else {
                    return (
                        <SidePanelDrawer
                            actionElement={
                                (props: any) => (
                                    <Flexbox align className={classes('h-full')} {...props}>
                                        <RiskStatusBox
                                            loading={evaluatingNodes.includes(params.data.id)}
                                            category={params.value?.category}
                                            alertsCount={params.value?.reasons?.length}
                                        />
                                    </Flexbox>
                                )
                            }
                            disabled={evaluatingNodes.includes(params.data.id)}
                        >
                            {
                                !!params.value?.reasons?.length && <AlertsSidePanel
                                    title={params.data.data.title}
                                    reasons={params.value.reasons}
                                />
                            }
                        </SidePanelDrawer>
                    );
                }
            },
            comparator: (d1, d2) => {
                const a = d1?.value
                const b = d2?.value

                const nullableField = null || undefined

                if (a === nullableField && b === nullableField) {
                    return 0;
                }
                if (a === nullableField) {
                    return -1;
                }
                if (b === nullableField) {
                    return 1;
                }

                if (a === 0 && b === 0) {
                    return 0;
                }
                if (a === 0) {
                    return -1;
                }
                if (b === 0) {
                    return 1;
                }

                return a - b;
            },
            keyCreator: (params: KeyCreatorParams) => {
                return params.value ? params.value?.category : 'N/A'
            }
        },
        {
            headerName: 'Status',
            field: 'data.status',
            minWidth: 120,
            sortable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
        },
        {
            colType: ColumnTypes.Priority,
            headerName: 'Priority',
            field: 'data.priority',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Start Date',
            field: 'data.start_date',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'End Date',
            field: 'data.end_date',
            minWidth: 120,
            sortable: true,
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            cellRendererParams: {
                isAddActive: false,
            },
            actions: params => {
                // We cannot rely on the data from `params` here because it may not reflect the latest state of the node's data.
                // This is because the column field does not directly correspond to the node's data properties.
                const currentNode = filteredNodes.find(node => node.id === params.data?.id)

                return [
                    ...(hasPermission(Actions.edit, params.data) ? [
                        {
                            label: 'Evaluate Risk',
                            action: () => {
                                if (params.data) {
                                    handleEvaluateNodeRisk(params.data.id)
                                }
                            },
                            disabled: evaluatingNodes.includes(params.data?.id) || (currentNode && +currentNode?.progress === 100 && currentNode?.data?.status === 'Done')
                        }
                    ] : []),
                ]
            },
        },

        // Design version
        // {
        //     headerName: 'Initiative',
        //     field: 'initiative',
        //     minWidth: 350,
        //     sortable: true,
        //     pinned: 'left' as boolean | 'left' | 'right' | null | undefined,
        //     suppressMovable: true,
        //     lockVisible: true,
        //     wrapText: true,
        //     autoHeight: true,
        //     headerComponent: ({ displayName }: any) => (
        //         <Flexbox justifyBetween align fullHeight fullWidth>
        //             <CustomTyphography className={classes('customHeader')}>{displayName}</CustomTyphography>
        //             <CommonButton>
        //                 Edit Columns
        //             </CommonButton>
        //         </Flexbox>
        //     ),
        //     cellRendererSelector: (params: ICellRendererParams) => {
        //         const hasGrouping = params.api.getRowGroupColumns().length > 0
        //         if (hasGrouping) {
        //             return { component: undefined }
        //         } else {
        //             return { component: 'agGroupCellRenderer' }
        //         }
        //     },
        //     cellRendererParams: {
        //         suppressCount: true,
        //         innerRenderer: (params: ICellRendererParams) => {
        //             return (
        //                 <Flexbox vertical className={classes('initiativeCell')}>
        //                     <CustomTyphography type='primary'>
        //                         {params.value.title}
        //                     </CustomTyphography>
        //                     <Flexbox justify className={classes('initiativeCell__numbers')}>
        //                         <CustomTyphography>{params.value.tasks.length} Tasks</CustomTyphography>
        //                         <CustomTyphography>&nbsp;•&nbsp;</CustomTyphography><CustomTyphography>{params.value.docs.length} Docs</CustomTyphography>
        //                     </Flexbox>
        //                 </Flexbox>
        //             )
        //         },
        //         suppressDoubleClickExpand: true,
        //         suppressEnterExpand: true,
        //     },
        // },
        // {
        //     headerName: 'Sources',
        //     field: 'sources',
        //     minWidth: 150,
        //     sortable: true,
        //     cellRenderer: (params: ICellRendererParams) => {
        //         const isGrouped = params.node.group;
        //         const isPivoted = params.node.leafGroup;

        //         if (!isGrouped && !isPivoted) {
        //             return (
        //                 <Flexbox wrap align className={classes('sourcesContainer')}>
        //                     {
        //                         params.value.map((el: any) => (
        //                             <Flexbox justify align className={classes('sourcesBox')}>
        //                                 <Link to={el.link}>
        //                                     {el.type?.charAt(0).toUpperCase()}
        //                                 </Link>
        //                             </Flexbox>
        //                         ))
        //                     }
        //                 </Flexbox>
        //             );
        //         } else {
        //             return <span>{params.value?.length > 0 ? params.value.join(', ') : params.value?.length === 0 ? '(Blanks)' : ''}</span>;
        //         }
        //     },
        // },
        // {
        //     headerName: 'Progress',
        //     field: 'progress',
        //     minWidth: 200,
        //     sortable: true,
        //     cellRenderer: (params: ICellRendererParams) => {
        //         return params.node.group && params.node.field !== 'progress' ? null : (
        //             params.value === null ? 'N/A' : (
        //                 <Flexbox align className={classes('h-full')}>
        //                     <ProgressBar value={params.value} />
        //                 </Flexbox>
        //             )
        //         )
        //     }
        // },
        // {
        //     headerName: 'Status',
        //     field: 'status',
        //     minWidth: 135,
        //     sortable: true,
        //     cellRenderer: (params: ICellRendererParams) => {
        //         const isGrouped = params.node.group;
        //         const isPivoted = params.node.leafGroup;

        //         if (!isGrouped && !isPivoted) {
        //             return (
        //                 <Flexbox align className={classes('h-full')}>
        //                     <RiskStatusBox value={params.value} />
        //                 </Flexbox>
        //             );
        //         } else {
        //             return <span>{params.value?.length > 0 ? params.value.join(', ') : params.value?.length === 0 ? '(Blanks)' : ''}</span>;
        //         }
        //     },
        // },
        // {
        //     headerName: 'PRD Score',
        //     field: 'prdScore',
        //     minWidth: 120,
        //     sortable: true,
        //     cellRenderer: (params: ICellRendererParams) => {
        //         if (params.value === null) {
        //             return 'N/A'
        //         } else {
        //             return (
        //                 <SidePanelDrawer
        //                     paperClassName='prdScoreDrawer'
        //                     actionElement={(props: any) => <Flexbox align className={classes('h-full')} {...props}><PRDScoreBox value={params.value} /></Flexbox>}
        //                 >
        //                     <PRDScoreSidePanel
        //                         description={'The PRD is still missing some critical information to make it ready for development.'}
        //                         score={params.data.score}
        //                         problems={mockedProblems}
        //                     />
        //                 </SidePanelDrawer>
        //             )
        //         }
        //     }
        // },
        // {
        //     headerName: 'Owners',
        //     field: 'owners',
        //     minWidth: 120,
        //     sortable: true,
        //     cellRenderer: (params: ICellRendererParams) => {
        //         return (
        //             <Flexbox align fullHeight>
        //                 {params.data.owners.map((owner: any) => (
        //                     <Flexbox>
        //                         {owner.fullName}
        //                     </Flexbox>
        //                 ))}
        //             </Flexbox>
        //         )
        //     }
        // },
        // {
        //     colType: ColumnTypes.Circle,
        //     headerName: 'Team',
        //     field: 'team',
        //     minWidth: 120,
        //     sortable: true,
        // },
        // {
        //     colType: ColumnTypes.Date,
        //     headerName: 'Due Date',
        //     field: 'dueDate',
        //     minWidth: 120,
        //     sortable: true,
        // },
        // {
        //     colType: ColumnTypes.Action,
        //     field: 'actions',
        //     headerName: '',
        //     actions: params => {
        //         return [
        //             ...(hasPermission(Actions.delete, params.data) ? [
        //                 { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
        //             ] : [])
        //         ]
        //     },
        // },
    ], [evaluatingNodes, gridApi, filteredNodes])

    const updateCurrentNode = ({ nodeId, nodeTitle, linkNodes }: { nodeId: number, nodeTitle: string, linkNodes: LinkNode[] }) => {
        setCurrentNode({ nodeId, nodeTitle, linkNodes })
    }

    const resetCurrentNode = () => {
        setCurrentNode(null)
    }

    const handleOpenLinkingNodesModal = ({ nodeId, nodeTitle, linkNodes }: { nodeId: number, nodeTitle: string, linkNodes: LinkNode[] }) => {
        setOpenLinkingNodesModal(true)
        updateCurrentNode({ nodeId, nodeTitle, linkNodes })
    }

    const handleCloseLinkingNodesModal = () => {
        setOpenLinkingNodesModal(false)
        resetCurrentNode()
    }

    const handleOpenLinkToNodesModal = ({ linkNodes, nodeId, nodeTitle }: { linkNodes: any[], nodeId: number, nodeTitle: string }) => {
        setOpenLinkToNodesModal(true)
        updateCurrentNode({ linkNodes, nodeId, nodeTitle })
    }

    const handleCloseLinkToNodesModal = () => {
        setOpenLinkToNodesModal(false)
        resetCurrentNode()
    }

    // const detailCellRendererParams = useMemo(() => {
    //     return {
    //         detailGridOptions: {
    //             columnDefs: [
    //                 {
    //                     field: 'title',
    //                     headerName: 'Title',
    //                     flex: 1,
    //                     sortable: false
    //                 },

    //             ] as AgColumn[]
    //         },
    //         getDetailRowData: (params: GetDetailRowDataParams) => params.successCallback(params.data.initiative.docs),
    //     }
    // }, []);
    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', getTextAfterDot(value.colId))
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
            preferencesRef.current = { executionView: preferencesRef.current.executionView, ...modifiedSearchParamsObject }
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionView));
        }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        preferencesRef.current.executionView = data

        dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionView));
    }, [preferencesRef]);

    useEffect(() => {
        if (debouncedQuery !== null) {
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionView));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    useEffect(() => {
        if (nodes) {
            const filteredData = nodes.filter((node: Node) => node.data.title?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            setFilteredNodes(filteredData)
        }
    }, [searchValue, nodes])

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return (
        <Flexbox fullWidth vertical>
            {
                (isLoading || getViewLoading || !view || viewNodesLoading || !nodesData) ? <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox> : (
                    <>
                        <ViewHeader
                            gridApi={gridApi}
                            updateQueryPreference={updateQueryPreference}
                            searchValue={searchValue}
                            emptyNodes={Array.isArray(nodesData) && nodesData.length === 0}
                            viewSources={view.sources}
                        />
                        <Flexbox className={classes('viewsTableContainer')}>
                            <AgGridTable
                                data={filteredNodes}
                                columns={columns}
                                onGridReady={onGridReady}
                                exportFileName={`Execution View-${view.name}`}
                                onSortChanged={onSortChanged}
                                order={preferencesRef.current.order}
                                orderBy={preferencesRef.current.orderBy}
                                gridStatePreferences={preferencesRef.current.executionView}
                                onGridStateChanged={onGridStateChanged}
                                readOnlyEdit={true}
                                getRowId={getRowId}
                            // masterDetail
                            // isRowMaster={(params: any) => params.initiative.docs.length > 0 || params.initiative.tasks.length > 0}
                            // detailCellRendererParams={detailCellRendererParams}
                            />
                        </Flexbox>
                    </>
                )
            }
            {
                openLinkingNodesModal && currentNode && (
                    <LinkedNodesModal
                        open
                        handleClosePopup={handleCloseLinkingNodesModal}
                        nodeId={currentNode.nodeId}
                        nodeTitle={currentNode.nodeTitle}
                        linkNodes={currentNode.linkNodes}
                    />
                )
            }
            {
                openLinkToNodesModal && currentNode && (
                    <LinkNodeModal
                        open
                        handleClosePopup={handleCloseLinkToNodesModal}
                        nodeId={currentNode.nodeId}
                        nodeTitle={currentNode.nodeTitle}
                        linkNodes={currentNode.linkNodes}
                    />
                )
            }
            <CustomSnackbar open={!!viewError || !!evaluateNodeRiskError} type='error' title={viewError || evaluateNodeRiskError} />
            <CustomSnackbar open={!!evaluateSuccessMessage} type='success' title={evaluateSuccessMessage} />
        </Flexbox>
    )
}

export default View