import { useEffect, useMemo, useState } from 'react';
import { RichTextEditor } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_ADD_BLOCK_ITEM, currentKbOwnerSelector, knowledgeBaseCurrentBlockSelector, knowledgeBaseCurrentId } from 'store/knowledgeBase';
import { blocksBaseUrl } from '../knowledgeBase.api';
import { Actions, hasPermission } from 'utils/permissions';
import { AttachmentMetadata, Block, LinkMetadata, TextMetadata, isTextBlock } from 'utils/types';
import { createText, updateText } from 'common/resourcesBlock/index.api';

const Content = () => {
    const dispatch = useDispatch()
    const ownerSelector = useSelector(currentKbOwnerSelector);
    const blocks: Block<LinkMetadata | AttachmentMetadata | TextMetadata>[] = useSelector(knowledgeBaseCurrentBlockSelector);
    const currentKbId = useSelector(knowledgeBaseCurrentId);

    const textData = useMemo(() => {
        const textBlock = blocks.find(b => isTextBlock(b)) as Block<TextMetadata>;

        return textBlock || undefined;
    }, [blocks])

    const [content, setContent] = useState(textData?.metadata.content);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [textError, setTextError] = useState('');

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            if(timeoutId){
                if(textData?.id){
                    update()
                }else{
                    create()
                }
            }

        }, 1000);

        setTimeoutId(newTimeoutId);
    }, [content])

    const create = async () => {
        console.log('create')
        const baseUrl = blocksBaseUrl(currentKbId)
        try {
            const textData = (await dispatch(createText({ content }, baseUrl))) as unknown as Block<LinkMetadata>
            dispatch({ type: ACTION_ADD_BLOCK_ITEM, payload: textData })
        } catch (e: any) {
            if(e.data.error) {
                setTextError(e.data.error)
            }
        }
    }

    const update = async () => {
        console.log('update')
        const baseUrl = blocksBaseUrl(currentKbId)
            try {
                await dispatch(updateText(textData.id, { content }, baseUrl))
            } catch (e: any) {
                if(e.data.error) {
                    setTextError(e.data.error)
                }
            }
    }

    const onContentChange = (value: string) => {
        setContent(value);
        setTextError('');
    }

    return(
        <RichTextEditor
            value={content}
            onChange={onContentChange}
            placeholder='Input your insights: learning, strategy, requirements, specs, legal. No character limit!'
            label='Content'
            disabled={!hasPermission(Actions.edit, { owner: ownerSelector })}
            files={[]}
            editorMinHeight={500}
            errorText={textError}
        />
    )
}

export default Content