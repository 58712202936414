import { ChangeEvent, createRef, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Button, Flexbox } from 'components'
import classNames from 'classnames/bind';
import styles from './fileUploader.module.scss';
import { AttachFileIcon, PlusIcon } from 'components/icons';
import request from 'utils/request';
import { AttachmentMetadata, Block, BlockTypes } from 'utils/types';
import React from 'react';
import store from 'store';
import blobToBase64 from 'utils/blobToBase64';
const classes = classNames.bind(styles);
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { retryUploadUuIdSelector } from 'store/knowledgeBase';

export interface UploadedFile  {
    status?: number;
    id?: number;
    error?: string;
    name: string;
    extension: string;
    url: string;
}

interface FileSelectorProps {
    multiple?: boolean;
    url: string;
    buttonText?: string;
    buttonType?: 'iconButton' | 'button';
    buttonRenderer?: (onClick:((e: MouseEvent<SVGSVGElement | HTMLElement>) => void)) => ReactNode;
    onUploadStart?: (files: UploadedFile[]) => void;
    onUploadFinish?: (uploadedItem: Block<AttachmentMetadata>) => void;
    onUploadProgress?: (fileName: string, status: number) => void;
    onClick?: (e: MouseEvent<SVGSVGElement | HTMLElement>) => void;
    className?: string,
    onFileUploadStart?: (attachment: any) => void,
    onUploadError?: (fileName: string) => void,
    resetRetryCondition?: () => void,
    onSuccessCallback?: any
}

const CHUNK_SIZE = 25 * 1024 * 1024; // 25 MB
const MAX_CHUNK_SIZE = 50 * 1024 * 1024; // 50 MB

const FileSelector = React.forwardRef<HTMLDivElement, FileSelectorProps>(({
    multiple = false,
    url,
    buttonText = 'Upload',
    buttonType = 'button',
    buttonRenderer,
    onUploadStart,
    onUploadFinish,
    onUploadProgress,
    className,
    onClick,
    onFileUploadStart,
    onUploadError,
    resetRetryCondition,
    onSuccessCallback
 }, ref) => {
    const fileInputRef = createRef<HTMLInputElement>();
    const [failedUploads, setFailedUploads] = useState<{
        uuId: string,
        file: File,
        uploadUrl: string,
    }[]>([]);
    const [failedChunkUploads, setFailedChunkUploads] = useState<{
        uuId: string
        promises:(() => Promise<void>)[],
        fileName: string
    }[]>([])

    const retryUploadUuId = useSelector(retryUploadUuIdSelector)

    const openFilePicker = (e: MouseEvent<SVGSVGElement | HTMLElement>) => {
        if(fileInputRef.current) {
            if(onClick) {
                onClick(e)
            }
            fileInputRef.current.click();
        }
    }
    const onFileUploadProgress = (file: File, e : ProgressEvent) => {
        const status = Math.round(e.loaded * 100 / e.total);
        if(onUploadProgress){
            onUploadProgress(file.name, status)
        }
    }

    const uploadFile = async (file: File, uploadUrl: string, uuId: string) => {
        try {
            const res = await request.uploadFile(uploadUrl, file, onFileUploadProgress);
            if (onUploadFinish) {
                onUploadFinish(res.data);
            }
        } catch (err) {
            setFailedUploads(prev => [...prev, { file, uploadUrl, uuId }])
            if (onUploadError) {
                onUploadError(file.name);
            }
        }
    };

    const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        if(fileInputRef.current && fileInputRef.current.files) {
            const files = fileInputRef.current.files;
            const uploadingFiles: UploadedFile[] = [];
            for(let i = 0; i < files.length; i++) {
                const file = files[i];
                const f:UploadedFile  = {
                    extension: file.name.split('.').pop() || '',
                    name: file.name,
                    url: URL.createObjectURL(file),
                }
                uploadingFiles.push(f);

                const uploadUrl = `/workspaces/${store.getState().user.workspace.id}/${url}/attachment`.replaceAll('//', '/');
                const uploadChunkUrl = `/workspaces/${store.getState().user.workspace.id}/${url}/chunks`.replaceAll('//', '/');

                const uuId =  uuid();

                if(onFileUploadStart){
                    onFileUploadStart({
                        uuId,
                        type: BlockTypes.Attachment,
                        metadata: { fileName: file.name, disabled: true }
                    })
                }

                if(file.size > MAX_CHUNK_SIZE) {
                    uploadFileChunks(file, uploadChunkUrl, uuId);
                } else {
                    uploadFile(file, uploadUrl, uuId);
                }
            }
            if(onUploadStart) {
                onUploadStart(uploadingFiles);
            }

            if(fileInputRef.current) {
                fileInputRef.current.value = ''
            }
        }
    }

    async function uploadWithRetry(uploadPromises: (() => Promise<void>)[], fileName: string, uploadId: string) {
        let retries = 3;

        onUploadProgress && onUploadProgress(fileName, 1);

        while (retries > 0) {
            const progressPromises: Promise<void>[] = [];
            let completedPromises = 0;
            const failedPromises: (() => Promise<void>)[] = [];

            for (const promise of uploadPromises) {
                progressPromises.push(
                    promise().then(() => {
                        completedPromises++;
                        const percentComplete = (completedPromises / uploadPromises.length) * 100;
                        onUploadProgress && onUploadProgress(fileName, percentComplete);
                    }).catch((error) => {
                        failedPromises.push(promise);
                    })
                );
            }

            await Promise.all(progressPromises);

            if (failedPromises.length === 0) {
                setTimeout(() => onSuccessCallback(), 10000);
                return;
            }

            retries--;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        console.error('Maximum retries reached. Upload failed.');
        onUploadError && onUploadError(fileName);

        setFailedChunkUploads(prev => {
            return [
                ...prev,
                {
                    uuId: uploadId,
                    promises: uploadPromises,
                    fileName
                }
            ]
        });
    }

    async function uploadFileChunks(file: File, uploadChunkUrl: string, uploadId: string) {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        const uploadPromises: (() => Promise<void>)[] = [];

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const startByte = chunkIndex * CHUNK_SIZE;
            const endByte = Math.min(startByte + CHUNK_SIZE, file.size);

            const chunkData = file.slice(startByte, endByte);

            const base64Data = await blobToBase64(chunkData);

            const chunkPayload = {
                uploadId,
                chunkIndex: chunkIndex + 1,
                totalChunks,
                data: base64Data,
                fileName: file.name,
            };

            uploadPromises.push(() => sendChunkToServer(chunkPayload, uploadChunkUrl));
        }

        await uploadWithRetry(uploadPromises, file.name, uploadId);
    }

    async function sendChunkToServer(chunkPayload: {
        chunkIndex: number,
        data: string,
        fileName: string,
        totalChunks: number,
        uploadId: string
    }, uploadChunkUrl: string) {
        try {
            await request.post(uploadChunkUrl, chunkPayload)
        } catch (error) {
            console.error('Error uploading chunk:', error);
        }
    }

    useEffect(() => {
        if(retryUploadUuId) {
            const failedUpload = failedUploads.find(el => el.uuId === retryUploadUuId);
            const failedChunkUpload = failedChunkUploads.find(el => el.uuId === retryUploadUuId);

            if(failedUpload) {
                uploadFile(failedUpload.file, failedUpload.uploadUrl, failedUpload.uuId).finally(resetRetryCondition)
            }

            if(failedChunkUpload) {
                uploadWithRetry(failedChunkUpload.promises, failedChunkUpload.fileName, failedChunkUpload.uuId).finally(resetRetryCondition)
            }
        }
    }, [retryUploadUuId])

    return (
        <Flexbox ref={ref} className={classes('fileUploader', className)} fullWidth>
            {buttonRenderer ? (buttonRenderer(openFilePicker)) : (buttonType === 'iconButton' ?
                <AttachFileIcon  className={classes('attachBtnIcon')} onClick={openFilePicker} />
                :
                <Button startIcon={<PlusIcon />}  className={classes('attachBtn')} onClick={openFilePicker}>{buttonText}</Button>)
            }
            <input onChange={onFileSelect} multiple={multiple} type='file' ref={fileInputRef} className={classes('fileInput')}/>
        </Flexbox>
    )
})

export default FileSelector;