
import classNames from 'classnames/bind';
import styles from '../knowledgeBase.module.scss';
import { Flexbox, MenuItem, SelectStandard } from 'components';
import { KnowledgeBaseStatus } from 'utils/types';
import { useEffect, useRef } from 'react';
import { SelectChangeEvent } from 'components/SelectStandard';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_STATUS, currentKbOwnerSelector, currentKbStatusSelector } from 'store/knowledgeBase';
import { editKb } from '../knowledgeBase.api';
import { Actions, hasPermission } from 'utils/permissions';
const classes = classNames.bind(styles);


const Status = () => {
    const dispatch = useDispatch();

    const statusSelector = useSelector(currentKbStatusSelector);
    const ownerSelector = useSelector(currentKbOwnerSelector)

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        dispatch(editKb())
    }, [statusSelector]);

    const onStatusChange = (e: SelectChangeEvent<KnowledgeBaseStatus>) => {
        dispatch({ type: UPDATE_STATUS, payload: e.target.value })
    }

    return(
        <Flexbox className={classes('selectStatus')}>
            <SelectStandard
                value={statusSelector}
                onChange={onStatusChange}
                variant='filled'
                autoWidth
                disabled={!hasPermission(Actions.edit, { owner: ownerSelector })}
            >
                {Object.entries(KnowledgeBaseStatus).map(status => {
                    return (
                        <MenuItem
                            key={status[1]}
                            value={status[1]}
                            className={classes('menuItem')}
                        >{status[0]}</MenuItem>
                    )
                })}
            </SelectStandard>
        </Flexbox>
    )
}

export default Status;