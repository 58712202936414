
import MSnackbar, { SnackbarCloseReason, SnackbarProps as MSnackbarProps } from '@mui/material/Snackbar';

import styles from './index.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';
import { SyntheticEvent } from 'react';
import { Alert, AlertColor } from '@mui/material';
import { CheckCircleIcon, ErrorIcon, WarningIcon } from 'components/icons';

interface SnackbarProps extends MSnackbarProps {
    type: AlertColor;
    closeOnClickAway?: boolean;
}

const Snackbar = ({ type, closeOnClickAway, onClose, children, ...props } : SnackbarProps) => {

    const close = (event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway' && !closeOnClickAway) {
            return
        }
        if(onClose) {
            onClose(event, reason)
        }
    }

    return(
        <MSnackbar
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            className={classes('snackbarContainer')}
            onClose={close}
            classes={{
                anchorOriginBottomLeft: classes('bottomLeft')
            }}
            {...props}

        >
            <Alert
                severity={type}
                iconMapping={{
                    success: <CheckCircleIcon className={classes('successIcon')}/>,
                    warning: <WarningIcon className={classes('warningIcon')} />,
                    error: <ErrorIcon className={classes('errorIcon')} />,
                }}
                classes={{
                    standardSuccess: classes('success'),
                    standardWarning: classes('warning'),
                    standardError: classes('error'),
                }}
            >
                {children}
            </Alert>
        </MSnackbar>
    )
}

export default Snackbar