import { Dispatch, GetState } from 'store';
import { emit } from 'utils/request';


export const sendMessage = (query: string, onResponse: (value: string) => void, onError: (error: Error) => void) => (dispatch: Dispatch, getState: GetState) =>  {
    const storeState = getState();
    emit('message', {
        prompt: query,
        workspaceId: storeState.user.workspace.id,
        Authorization: `Bearer ${storeState.user.token}`
    },
    onResponse,
    onError);
}