import { Dialog, Flexbox, Switch, TextArea } from 'components'
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
const classes = classNames.bind(styles);
import classNames from 'classnames/bind';
import { AutogenerateLocationState } from 'utils/types';
import { ChangeEvent, memo, useEffect, useState } from 'react';


interface AutocompleteDialogProps{
    openAutoCompleteDialog: boolean;
    setOpenAutoCompleteDialog: (e: boolean) => void
}

const AutocompleteDialog = ({ openAutoCompleteDialog, setOpenAutoCompleteDialog }: AutocompleteDialogProps) => {
    const navigate = useNavigate()

    const [autoCompleteIdea, setAutoCompleteIdea] = useState('');
    const [knowledgeContext, setKnowledgeContext] = useState(false);

    useEffect(() => {
        if(openAutoCompleteDialog){
            setAutoCompleteIdea('')
        }
    }, [openAutoCompleteDialog])

    const onHideAutoComplete = () => {
        setOpenAutoCompleteDialog(false);
    }

    const onAutoCompleteIdeaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setAutoCompleteIdea(e.target.value);
    }

    const onAutocomplete = () => {
        if(autoCompleteIdea){
            const state: AutogenerateLocationState = { autoCompleteIdea, knowledgeContext }
            navigate('/initiatives/initiative/', { state });
        }
    }

    const onKnowledgeContextChange = () => {
        setKnowledgeContext(!knowledgeContext)
    }

    return(
        <Dialog
            open={openAutoCompleteDialog}
            onClose={onHideAutoComplete}
            title='Describe your feature'
            confirmButton
            confirmButtonLabel='Generate'
            onConfirm={onAutocomplete}
        >
            <Flexbox vertical className={classes('autocompleteDialogMainContainer')} justify>
                <TextArea
                    fullWidth
                    rows={4}
                    placeholder="Type your idea here..."
                    value={autoCompleteIdea}
                    onChange={onAutoCompleteIdeaChange}
                />
                <Flexbox justifyEnd>
                    <Switch
                        size='small'
                        label='Use knowledge base'
                        checked={knowledgeContext}
                        onChange={onKnowledgeContextChange}
                    />
                </Flexbox>
            </Flexbox>
        </Dialog>
    )
}

export default memo(AutocompleteDialog)