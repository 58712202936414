import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Button } from 'components';
import { ReactNode } from 'react';
import { PlusIcon } from 'components/icons';
import { ButtonVariants } from 'utils/types';
const classes = classNames.bind(styles);


interface EmptyStateProps{
    icon: ReactNode;
    title: string,
    titleSmall?: string,
    className?: string;
    buttonItems?: ButtonItem[];
}

interface ButtonItem {
    onClick?: () => void;
    text: string;
    icon?: ReactNode;
    variant?: ButtonVariants
}

const EmptyState = ({ icon, title, titleSmall, className, buttonItems } : EmptyStateProps) => {

    return(
        <Flexbox fullWidth vertical align className={classes('emptyContainer', className)}>
            <Flexbox className={classes('emptyIcon')}>
                {icon}
            </Flexbox>
            <Flexbox className={classes('titleLarge')}>
                {title}
            </Flexbox>
            {titleSmall &&
                <Flexbox className={classes('titleSmall')}>
                    {titleSmall}
                </Flexbox>
            }
            <Flexbox>
                {buttonItems && buttonItems.map((button) => (
                    <Button className={classes('emptyButton')} variant={button.variant || 'contained'} key={button.text} onClick={button.onClick}>
                        {button.text}
                        {button.icon || <PlusIcon />}
                    </Button>
                ))}
            </Flexbox>
        </Flexbox>
    )
}

export default EmptyState