import { useState, KeyboardEvent, useMemo, useRef, useEffect } from 'react';
import { Flexbox, Snackbar, TextArea } from 'components';
import classNames from 'classnames/bind';
import styles from './assistant.module.scss';
import { Logo, SendIcon } from 'components/icons';
import UserLogo from 'common/UserLogo';
import { useDispatch } from 'react-redux';
import { sendMessage } from './assistant.api';
import showdown from 'showdown';
import { AIStreamingEndCharCode } from 'utils/constants';
const classes = classNames.bind(styles);

interface Message {
    from: 'me' | 'ai';
    text: string;
}

export default () => {
    const [question, setQuestion] = useState('');
    const [messages, setMessages] = useState<Message[]>([]);

    const [counter, setCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const converter = useMemo(() => {
        return new showdown.Converter({ tables: true });
    }, []);

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const generatedAi = useRef('');
    const chatContainerRef = useRef<HTMLDivElement>(null);

    const onSendMessage = async () => {
        if (question) {
            messages.unshift({ from: 'me', text: question });
            setMessages([...messages]);
            setQuestion('');
            setCounter(current => current + 1);

            setIsLoading(true)
            dispatch(
                sendMessage(
                    question,
                    (value: string) => {
                        setCounter(current => current - 1)
                        if (value.charCodeAt(0) === AIStreamingEndCharCode) {
                            setIsLoading(false)
                            messages.unshift({ from: 'ai', text: generatedAi.current.trim().replace('```', '') });
                            setMessages([...messages]);
                            setCounter(current => current - 1);
                            generatedAi.current = '';
                            return;
                        }
                        generatedAi.current += value;
                        generatedAi.current = generatedAi.current.replace('```html', '');
                    },
                    () => {
                        setCounter(current => current - 1);
                        setIsLoading(false)
                        setError(true)
                    }
                )
            );
        }
    };

    useEffect(() => {
        if (chatContainerRef.current && generatedAi.current) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [generatedAi.current, messages]);

    return (
        <Flexbox className={classes('messengerContainer')} fullWidth vertical ref={chatContainerRef}>
            <Flexbox className={classes('messagesContainer')} fullWidth>
                {isLoading &&
                    <Flexbox className={classes('messageContainer')}>
                        <Flexbox className={classes('logoContainer')}>
                            <Logo />
                        </Flexbox>
                        <Flexbox>
                            {generatedAi.current.length ? (
                                <Flexbox
                                    fullWidth
                                    vertical
                                    dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(generatedAi.current),
                                    }}
                                />
                            ) : (
                                <Flexbox align className={classes('dotFlashingContainer')}>
                                    <Flexbox className={classes('dotFlashing')} />
                                </Flexbox>
                            )}
                        </Flexbox>
                    </Flexbox>
                }
                {messages.map((message, index) => (
                    <Flexbox
                        key={index}
                        className={classes('messageContainer', { fromAI: message.from === 'ai' })}
                    >
                        <Flexbox className={classes('logoContainer')}>
                            {message.from === 'me' ? <UserLogo /> : <Logo />}
                        </Flexbox>
                        {message.from === 'me' ? (
                            <Flexbox className={classes('message')}>{message.text}</Flexbox>
                        ) : (
                            <Flexbox
                                fullWidth
                                vertical
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(message.text),
                                }}
                            />
                        )}
                    </Flexbox>
                ))}
            </Flexbox>
            <Flexbox className={classes('newMessageContainer')} fullWidth>
                <TextArea
                    value={question}
                    onKeyDown={onKeyPress}
                    onChange={e => {
                        setQuestion(e.target.value);
                    }}
                    placeholder="Ask here"
                    className={classes('textArea')}
                    fullWidth
                    endAdornment={
                        <SendIcon className={classes('sendIcon')} onClick={onSendMessage} />
                    }
                    disabled={isLoading}
                />
            </Flexbox>
            <Snackbar open={error} onClose={() => setError(false)} type="error">
                <Flexbox>Sorry, An error occurred. Please try again later!</Flexbox>
            </Snackbar>
        </Flexbox>
    );
};
