import React, { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'store/user';
import {
    Flexbox,
    Loader,
    ConfirmationDialog,
    Tab,
    Tabs,
    ActionsMenu,
    Snackbar,
    SelectStandard,
    MenuItem,
    MultilineInput,
    Backdrop,
} from 'components';
import styles from './initiative.module.scss';
import {
    Block,
    Initiative,
    InitiativePriority,
    InitiativeSaveModel,
    InitiativeStatus,
    InitiativeType,
    Product,
    AutogenerateLocationState,
    InitiativeOKR,
    Contribution,
    Story,
    InitiativeMockup,
    OKR,
} from 'utils/types';
import {
    createInitiative,
    deleteInitiative,
    duplicateInitiative,
    editInitiative,
    generateInitiative,
    getInitiativeById,
    moveInitiativeToLive,
} from './initiatives.api';
import { getProducts } from 'pages/Products/products.api';
import RequirementsTab from './components/tabs/requirements';
import OkrTargetTab from './components/tabs/okrTarget/okrTarget';
import ResourcesTab from './components/tabs/resources';
import { Actions, hasPermission } from 'utils/permissions';
import { SelectChangeEvent } from 'components/SelectStandard';
import Plan from './components/tabs/plan';
import OverviewTab from './components/tabs/overview';
import SavingStatus, { SaveStatus } from 'common/savingStatus';
import { addMonths, addWeeks } from 'utils/date';
import ChangeLog from './components/changeLog';
import { ButtonItem } from 'components/ActionsMenu';
import MockupTab from './components/tabs/mockups';
import { AIStreamingEndCharCode, INITIATIVE_TABS, InitiativeTabKey, documentDefaultTitle } from 'utils/constants';
import JiraActions from './components/jiraActions';
import { SidebarContainer } from 'components/SidebarContainer';
import { CommentsSidebar } from 'components/Comments/CommentsSidebar/CommentsSidebar';
import { CommentsToggleButton } from 'components/Comments/CommentsToggleButton';
import { Divider, Stack } from 'components';
import { CommentNew } from 'components/Comments/CommentNew/CommentNew';
import { CommentThread } from 'components/Comments/CommentThread/CommentThread';
import { useCommentPageScroll, useComments } from 'store/comments-slice';
import { getSourceForCommentThread } from 'utils/comments';
import { activeUsersSelector } from 'store/users-slice';
import { teamsSelector } from 'store/teams-slice';
import { useLazyGetUsersQuery } from 'store/users-api';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import Execution from './components/tabs/execution';
import { pdf } from '@react-pdf/renderer';
import InitiaitvePdf from './components/initiaitvePdf';
import { saveAs } from 'file-saver';
import { getStory } from 'common/Story/index.api';
import { getMockups } from './components/tabs/mockups/mockups.api';
import { toPng } from 'html-to-image';
import axios from 'axios';
import { useWorkspaceId } from 'utils/hooks';
import { removeCurrentCommentFromClass } from 'utils/removeTextFromClass';


const classes = classNames.bind(styles);

let saveTimer: NodeJS.Timeout | null = null;

const urlTabParamKey = 'tab';

const defaultTitle = 'Untitled Initiative';

const InitiativeForm = () => {
    const [title, setTitle] = useState('');
    const [problem, setProblem] = useState('');
    const [status, setStatus] = useState(InitiativeStatus.Backlog);
    const [size, setSize] = useState<string>('M');
    const [priority, setPriority] = useState(InitiativePriority.Medium);
    const [type, setType] = useState(InitiativeType['Improvement']);
    const [validation, setValidation] = useState('');
    const [solution, setSolution] = useState('');

    const [owner, setOwner] = useState<number | undefined>();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(addMonths());
    const [releaseDate, setReleaseDate] = useState<Date | null>(addWeeks(1, addMonths()));
    const [affectedProducts, setAffectedProducts] = useState<Product[]>([]);
    const [gettingInitiative, setGettingInitiative] = useState(true);
    const [successDefinition, setSuccessDefinition] = useState('');
    const [blocks, setBlocks] = useState<Block[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [teams, setTeam] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [okrs, setOkrs] = useState<InitiativeOKR[]>([]);
    const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);

    const [openMoveToLiveDialog, setOpenMoveToLiveDialog] = useState(false);
    const [openDuplicateConfirmation, setOpenDuplicateConfirmation] = useState(false);

    const [initiativeStatusAlert, setInitiativeStatusAlert] = useState(false);

    const [generatingInitiative, setGeneratingInitiative] = useState(false);

    const [savingStatus, setSavingStatus] = useState<SaveStatus>(SaveStatus.UNSET);

    const [showChangeLog, setShowChangeLog] = useState(false);

    const [currentInitiative, setCurrentInitiative] = useState<Initiative | undefined>();

    const [initiativeDuplicationSuccess, setInitiativeDuplicationSuccess] = useState<boolean>(false)
    const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false)

    const params = useParams()
    const initiativeId = params['id'] ? parseInt(params['id']) : undefined;

    const location = useLocation();
    const state = location.state as AutogenerateLocationState;

    const workspaceId = useWorkspaceId();

    const user = useSelector(userSelector);
    const users = useSelector(activeUsersSelector);
    const teamsList = useSelector(teamsSelector);

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTabKey = searchParams.get(urlTabParamKey) as InitiativeTabKey;
    const selectedTab = INITIATIVE_TABS.indexOf(selectedTabKey || '');

    useEffect(() => {
        if (title) {
            document.title = `${title} - ${documentDefaultTitle}`
        }
    }, [title]);

    const {
        editorNewCommentProps,
        isCommentsOn,
        isCreatingComment,
        isCreatingNewComment,
        comments,
        selectedComment,
        highlightedComment,
        toggleComments,
        cancelCommentCreation,
        commentHighlightHandler,
        executeCommentCreation,
    } = useComments({
        workspaceId,
        objectKey: 'initiatives',
        objectId: initiativeId,
        onCommentSelect: comment => {
            const tab: InitiativeTabKey = comment.fieldKey.startsWith('overview') ? 'overview' :
                comment.fieldKey.startsWith('impact') ? 'impact' : 'requirements';
            setSearchParams(`${urlTabParamKey}=${tab}`, { replace: true });
        }
    });
    useCommentPageScroll(comments, (comment) => {
        if (comment.fieldKey.startsWith('overview')) {
            return selectedTabKey === 'overview';
        }
        if (comment.fieldKey.startsWith('impact')) {
            return selectedTabKey === 'impact'
        }
        return selectedTabKey === 'requirements';
    });

    const filterOkrsByImpact = (okrs: InitiativeOKR[]) => {
        let newOkrs = okrs;

        if (!okrs.length && !!state?.okrId) {
            newOkrs = [{ okrId: state.okrId, contributions: [] }];
        }

        return newOkrs.map(okr => ({
            ...okr,
            contributions: okr.contributions.filter((cont: Contribution) => cont.impact),
        }));
    };

    useEffect(() => {
        if (gettingInitiative || generatingInitiative) {
            return;
        }

        if (saveTimer) {
            clearTimeout(saveTimer);
        }
        saveTimer = setTimeout(() => {
            createInitiativeHandler();
        }, 500);
    }, [
        title,
        problem,
        size,
        priority,
        type,
        validation,
        startDate,
        endDate,
        releaseDate,
        solution,
        owner,
        selectedProducts,
        affectedProducts,
        teams,
        successDefinition,
        okrs,
    ]);

    useEffect(() => {
        if (gettingInitiative) {
            return;
        }
        if (initiativeId) {
            if (status === InitiativeStatus.Live) {
                dispatch(moveInitiativeToLive(initiativeId));
            } else {
                createInitiativeHandler();
            }
        }
    }, [status]);

    const fillingFieldName = useRef('');
    const isFillingField = useRef(false);
    const setter = useRef<React.Dispatch<React.SetStateAction<string>> | undefined>();
    const fillingFieldValue = useRef('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getUsers] = useLazyGetUsersQuery();
    const [getTeams] = useLazyGetTeamsQuery();

    useEffect(() => {
        dispatch(getProducts());
        getUsers({ workspaceId });
        getTeams({ workspaceId });
    }, []);

    useEffect(() => {
        loadInitiative();

        // This is here to handle creating new initiative case
        const tab = searchParams.get(urlTabParamKey) as InitiativeTabKey;
        if (!tab || !INITIATIVE_TABS.includes(tab)) {
            setSearchParams(`${urlTabParamKey}=${INITIATIVE_TABS[0]}`, { replace: true });
        }
    }, [initiativeId]);

    useEffect(() => {
        setIsLoading(!initiativeId || gettingInitiative);
    }, [initiativeId, gettingInitiative]);

    const loadInitiative = async () => {
        if (initiativeId) {
            setGettingInitiative(true);

            type InitiativeResponse = Initiative | { error: any };

            const initiative: InitiativeResponse = (await dispatch(
                getInitiativeById(initiativeId)
            )) as unknown as Initiative;

            if ('error' in initiative) {
                return;
            }

            setTitle(initiative.title);
            setProblem(removeCurrentCommentFromClass(initiative.problem || '').replace(/<del>/g, '<s>').replace(/<\/del>/g, '</s>'));
            setStatus(initiative.status);
            setSize(initiative.size);
            setPriority(initiative.priority);
            setType(initiative.type);
            setValidation(removeCurrentCommentFromClass(initiative.validation || '').replace(/<del>/g, '<s>').replace(/<\/del>/g, '</s>'));
            setSolution(removeCurrentCommentFromClass(initiative.solution || '').replace(/<del>/g, '<s>').replace(/<\/del>/g, '</s>'));

            setOwner(initiative.owner.id);
            setBlocks(initiative.blocks);
            setOkrs(initiative.okrs || []);
            setSuccessDefinition(initiative?.successDefinition || '');
            setStartDate(initiative.startDate ? new Date(initiative.startDate) : null);
            setEndDate(initiative.endDate ? new Date(initiative.endDate) : null);
            setReleaseDate(initiative.releaseDate ? new Date(initiative.releaseDate) : null);
            setAffectedProducts(initiative.affectedProducts || []);
            setSelectedProducts(initiative.products || []);
            setTeam(initiative.teams?.map(t => t.id) || []);

            setCurrentInitiative(initiative)

            setTimeout(() => { setGettingInitiative(false); }, 10)
        } else {
            const initiative: InitiativeSaveModel = {
                problem,
                title: defaultTitle,
                priority,
                size,
                type,
                status,
                validation,
                solution,
                okrs: filterOkrsByImpact(okrs),
                startDate: startDate ? startDate.getTime() : null,
                endDate: endDate ? endDate.getTime() : null,
                releaseDate: releaseDate ? releaseDate.getTime() : null,
                owner: user.id,
                affectedProducts: affectedProducts.map(product => product.id),
                products: selectedProducts.map(product => product.id),
                teams,
            };
            const res: any = await dispatch(createInitiative(initiative));
            if (res.error) {
                console.log(res.error);
            } else {
                navigate(`/initiatives/initiative/${res.id}`, { replace: true });

                if (state) {
                    onAutocomplete(state.autoCompleteIdea, res.id, state.knowledgeContext);
                }
            }
        }
    };

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const onStatusChange = (e: SelectChangeEvent<InitiativeStatus>) => {
        if (e.target.value) {
            const newStatus = e.target.value;
            if (newStatus === InitiativeStatus.Live) {
                if (selectedProducts.length) {
                    onOpenMoveToLiveDialog();
                } else {
                    setInitiativeStatusAlert(true);
                }
            } else {
                setStatus(newStatus as InitiativeStatus);
            }
        }
    };

    const closeInitiativeStatusAlert = (_event?: SyntheticEvent | Event, reason?: string) => {
        setInitiativeStatusAlert(false);
    };

    const createInitiativeHandler = async () => {
        const initiative: InitiativeSaveModel = {
            problem,
            title,
            priority,
            solution,
            size,
            type,
            status,
            validation,
            okrs: filterOkrsByImpact(okrs),
            startDate: startDate ? startDate.getTime() : null,
            endDate: endDate ? endDate.getTime() : null,
            releaseDate: releaseDate ? releaseDate.getTime() : null,
            owner,
            affectedProducts: affectedProducts.map(product => product.id),
            products: selectedProducts.map(product => product.id),
            teams,
            successDefinition,
        };
        setSavingStatus(SaveStatus.SAVING);
        const res: any = await dispatch(
            initiativeId ? editInitiative({
                ...initiative,
                problem: initiative.problem?.replace(/(\s*\bcurrentComment\b)/g, '').replace(/<s>/g, '<del>').replace(/<\/s>/g, '</del>'),
                validation: initiative.validation?.replace(/(\s*\bcurrentComment\b)/g, '').replace(/<s>/g, '<del>').replace(/<\/s>/g, '</del>'),
                solution: initiative.solution?.replace(/(\s*\bcurrentComment\b)/g, '').replace(/<s>/g, '<del>').replace(/<\/s>/g, '</del>'),
            }, initiativeId) : createInitiative(initiative)
        );
        setSavingStatus(SaveStatus.SAVED);
        setTimeout(() => {
            setSavingStatus(SaveStatus.UNSET);
        }, 2000);
        if (res.error) {
            console.log(res.error);
        }
    };

    const showDeleteConfirmation = () => {
        setOpenConfirmation(true);
    };

    const onCancelDelete = () => {
        setOpenConfirmation(false);
    };

    const onReleaseDateChange = (date: Date | null) => {
        if (date) {
            date.setUTCHours(0, 0, 0, 0);
        }
        setReleaseDate(date);
    };

    const onStartDateChange = (date: Date | null) => {
        if (date) {
            date.setUTCHours(0, 0, 0, 0);
        }
        setStartDate(date);
    };
    const onEndDateChange = (date: Date | null) => {
        if (date) {
            date.setUTCHours(0, 0, 0, 0);
        }
        setEndDate(date);
    };

    const onDeleteConfirmation = async () => {
        if (initiativeId) {
            const res: any = await dispatch(deleteInitiative(initiativeId));
            if (res.error) {
                console.log(res.error);
            } else {
                onCancelDelete();
                navigate('/initiatives');
            }
        }
    };

    const onGeneratePrdResponse = (value: string) => {
        if (value.charCodeAt(0) === 8201) {
            setter.current = undefined;
            isFillingField.current = true;
            fillingFieldName.current = '';
            fillingFieldValue.current = '';
        } else if (value.charCodeAt(0) === 8202) {
            isFillingField.current = false;

            if (fillingFieldName.current.includes('[title]')) {
                setTitle('');
                setter.current = setTitle;
            } else if (fillingFieldName.current.includes('[problem]')) {
                setProblem('');
                setter.current = setProblem;
            } else if (fillingFieldName.current.includes('[solution]')) {
                setSolution('');
                setter.current = setSolution;
            } else if (fillingFieldName.current.includes('[validation]')) {
                setValidation('');
                setter.current = setValidation;
            }
        } else if (value.charCodeAt(0) === AIStreamingEndCharCode) {
            setter.current = undefined;
            isFillingField.current = false;
            fillingFieldName.current = '';
            setGeneratingInitiative(false);
        } else {
            if (isFillingField.current) {
                fillingFieldName.current += value;
            } else {
                if (setter.current) {
                    fillingFieldValue.current += value;
                    setter.current(fillingFieldValue.current.trim());
                }
            }
        }
    };

    const onAutocomplete = async (
        autoCompleteIdea: string,
        initiativeId: number,
        knowledgeContext: boolean
    ) => {
        if (initiativeId && !generatingInitiative) {
            setGeneratingInitiative(true);
            try {
                dispatch(
                    generateInitiative(
                        initiativeId,
                        autoCompleteIdea,
                        onGeneratePrdResponse,
                        () => {
                            setGeneratingInitiative(false);
                        },
                        knowledgeContext
                    )
                );
            } catch (error) {
            } finally {
            }
        }
    };

    const onOpenMoveToLiveDialog = () => {
        setOpenMoveToLiveDialog(true);
    };

    const onCloseMoveToLiveDialog = () => {
        setOpenMoveToLiveDialog(false);
    };

    const onOpenDuplicationDialog = () => {
        setOpenDuplicateConfirmation(true);
    };

    const onCloseDuplicationDialog = () => {
        setOpenDuplicateConfirmation(false);
    };

    const onMoveToLive = () => {
        setStatus(InitiativeStatus.Live);
        onCloseMoveToLiveDialog();
    };

    const onTabChange = (_event: ChangeEvent<{}>, value: number) => {
        setSearchParams(`${urlTabParamKey}=${INITIATIVE_TABS[value]}`, { replace: true });
    };

    const onShowChangeLog = () => {
        setShowChangeLog(true);
    };

    const onHideChangeLog = () => {
        setShowChangeLog(false);
    };

    const ownerUser = users.find(user => user.id === owner);
    const isEditable = hasPermission(Actions.edit, { owner: ownerUser });



    const baseUrl = `initiatives/${currentInitiative?.id}/stories`;
    const [stories, setStories] = useState<Story[]>([]);
    const [allMockups, setAllMockups] = useState<InitiativeMockup[]>([]);

    useEffect(() => {
        if (currentInitiative?.id) {
            const loadStory = async () => {
                const stories: Story[] = (await dispatch(getStory(baseUrl))) as unknown as Story[]
                setStories(stories)
            }
            loadStory()
        }
    }, [currentInitiative?.id])

    const mockupLinksRef = useRef<string[]>([]);
    const [exportPdfIsLoading, setExportPdfIsLoading] = useState(false)

    useEffect(() => {
        if (!currentInitiative?.id) { return; }

        const loadMockups = async () => {
            try {
                const mockups = (await dispatch(
                    getMockups(currentInitiative.id)
                )) as unknown as InitiativeMockup[];
                setAllMockups(mockups);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        loadMockups();
    }, [currentInitiative?.id]);

    const generateMockupImg = useCallback(async () => {
        const mockupDataPromises: Promise<string>[] = allMockups.map(async (mockup) => {
            const latestVersion = mockup.versions.find((v) => v.latest);
            const response = await axios.get(latestVersion?.downloadUrl || mockup.versions[0].downloadUrl);

            document.body.style.padding = '0'
            setTimeout(() => { document.body.style.overflow = 'hidden' }, 0)

            const div = document.createElement('div');
            div.innerHTML = response.data;
            document.body.appendChild(div);

            const dataUrl = await toPng(div);
            document.body.removeChild(div);

            return dataUrl;
        });

        const mockupData = await Promise.all(mockupDataPromises);
        mockupLinksRef.current = mockupData;
    }, [allMockups])


    const onExportPdf = useCallback(async () => {
        if (!currentInitiative) {
            return;
        }
        setExportPdfIsLoading(true)
        await generateMockupImg()
        const asPdf = pdf();
        asPdf.updateContainer(<InitiaitvePdf initiative={currentInitiative} stories={stories} mockupLinks={mockupLinksRef.current} affectedOkr={filteredOkrs.filter(okr => okrs.some(el => el.okrId === okr.id))} />);
        const blob = await asPdf.toBlob();

        saveAs(blob, currentInitiative.title);

        document.body.style.padding = '';
        document.body.style.overflow = '';

        setExportPdfIsLoading(false)
    }, [currentInitiative, stories, filteredOkrs, okrs]);

    const handleDuplicateInitiative = async () => {
        if (initiativeId) {
            try {
                setDuplicateLoading(true)
                const duplicatedInitiative = (await dispatch(duplicateInitiative(initiativeId))) as unknown as Initiative
                setInitiativeDuplicationSuccess(true);
                onCloseDuplicationDialog();
                setTimeout(() => {
                    setInitiativeDuplicationSuccess(false);
                    navigate(`/initiatives/initiative/${duplicatedInitiative.id}`)
                }, 800)
            } catch (err) {
                console.log(err);
            } finally {
                setDuplicateLoading(false)
            }
        }
    }

    const actions: ButtonItem[] = [
        { label: 'Change Log', action: onShowChangeLog },
        { label: 'Export to PDF', action: onExportPdf, isLoading: exportPdfIsLoading, disabled: exportPdfIsLoading },
        { label: 'Duplicate', action: onOpenDuplicationDialog, isLoading: duplicateLoading, disabled: duplicateLoading },
        { label: 'Delete', action: showDeleteConfirmation, type: 'red' },
    ];

    if (isLoading) {
        return (
            <Flexbox fullWidth align justify vertical>
                <Loader disableShrink />
            </Flexbox>
        );
    }

    return (
        <Fragment>
            <SidebarContainer
                open={isCommentsOn}
                drawerContent={
                    <CommentsSidebar onClose={toggleComments}>
                        {isCreatingNewComment && (
                            <CommentNew
                                author={user}
                                sending={isCreatingComment}
                                disabled={isCreatingComment}
                                onClose={cancelCommentCreation}
                                onSend={executeCommentCreation}
                                {...editorNewCommentProps}
                            />
                        )}
                        {comments?.map(comment => {
                            return (
                                <CommentThread
                                    key={comment.id + '-thread'}
                                    collapsed
                                    source={getSourceForCommentThread(comment)}
                                    comment={comment}
                                    selected={comment.id === selectedComment?.id}
                                    highlighted={comment.id === highlightedComment?.id}
                                    onClick={commentHighlightHandler(comment)}
                                    onReply={executeCommentCreation}
                                    canResolve={comment.author.id === user.id || owner === user.id}
                                    {...editorNewCommentProps}
                                />
                            );
                        })}
                    </CommentsSidebar>
                }
            >
                <Flexbox vertical fullWidth className={classes('mainContainer')}>
                    <Flexbox vertical className={classes('infoContainer')}>
                        <Flexbox className={classes('infoTopBox')}>
                            <Flexbox className={classes('infoTitleBox')} fullWidth>
                                <MultilineInput
                                    className={classes('infoTitleInput')}
                                    value={title === defaultTitle ? '' : title}
                                    disabled={!isEditable}
                                    onChange={onTitleChange}
                                    placeholder="Create title"
                                    fullWidth
                                />
                            </Flexbox>
                            <Stack direction="row" spacing={1}>
                                <SavingStatus savingStatus={savingStatus} />

                                <JiraActions
                                    currentInitiative={currentInitiative}
                                    isEditable={isEditable}
                                />

                                <Flexbox className={classes('selectStatus')}>
                                    <SelectStandard
                                        onChange={onStatusChange}
                                        value={status}
                                        variant="filled"
                                        autoWidth
                                        disabled={status === InitiativeStatus.Live || !isEditable}
                                    >
                                        {Object.entries(InitiativeStatus).map(status => {
                                            return (
                                                <MenuItem
                                                    key={status[1]}
                                                    value={status[1]}
                                                    className={classes('menuItem')}
                                                >
                                                    {status[0]}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectStandard>
                                </Flexbox>
                                {hasPermission(Actions.delete, { owner: ownerUser }) && (
                                    <ActionsMenu
                                        buttonItems={actions}
                                        className={classes('actionMenu')}
                                    />
                                )}
                            </Stack>
                        </Flexbox>
                    </Flexbox>
                    <Stack direction="row">
                        <Tabs
                            value={selectedTab}
                            onChange={onTabChange}
                            className={classes('tabs')}
                        >
                            <Tab label="Overview" id={'0'} />
                            <Tab label="Execution" id={'1'} />
                            <Tab label="Mockups" id={'2'} />
                            <Tab label="Plan" id={'3'} />
                            <Tab label="Impact" id={'4'} />
                            <Tab label="Requirements" id={'5'} />
                            <Tab label="Resources" id={'6'} />
                        </Tabs>
                        <span className={classes('comment-button-container')}>
                            <Stack direction="row" alignItems="center" spacing={3}>
                                <Divider flexItem orientation="vertical" variant="middle" />
                                <CommentsToggleButton
                                    checked={isCommentsOn}
                                    onClick={toggleComments}
                                />
                            </Stack>
                        </span>
                    </Stack>
                    <OverviewTab
                        active={selectedTab === 0}
                        priority={priority}
                        setPriority={setPriority}
                        size={size}
                        setSize={setSize}
                        type={type}
                        setType={setType}
                        problem={problem}
                        setProblem={setProblem}
                        solution={solution}
                        setSolution={setSolution}
                        validation={validation}
                        setValidation={setValidation}
                        isEditable={isEditable}
                        generatingInitiative={generatingInitiative}
                        initiativeId={initiativeId}
                        ownerId={owner}
                        fillingFieldName={fillingFieldName.current}
                    />
                    <Execution active={selectedTab === 1} />
                    <MockupTab
                        active={selectedTab === 2}
                        allMockups={allMockups}
                        setAllMockups={setAllMockups}
                        isLoading={isLoading}
                        initiativeId={initiativeId}
                    />
                    <Plan
                        active={selectedTab === 3}
                        owner={owner}
                        setOwner={setOwner}
                        startDate={startDate}
                        setStartDate={onStartDateChange}
                        endDate={endDate}
                        setEndDate={onEndDateChange}
                        releaseDate={releaseDate}
                        setReleaseDate={onReleaseDateChange}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        affectedProducts={affectedProducts}
                        setAffectedProducts={setAffectedProducts}
                        isEditable={isEditable}
                        teams={teams}
                        setTeam={setTeam}
                        teamsList={teamsList}
                        initiativeId={initiativeId}
                    />
                    <OkrTargetTab
                        active={selectedTab === 4}
                        okrs={okrs}
                        successDefinition={successDefinition}
                        setSuccessDefinition={setSuccessDefinition}
                        status={status}
                        initiativeReleaseDate={releaseDate}
                        isEditable={isEditable}
                        ownerId={owner}
                        initiativeId={initiativeId}
                        setOkrs={setOkrs}
                        filteredOkrs={filteredOkrs}
                        setFilteredOkrs={setFilteredOkrs}
                    />
                    <RequirementsTab
                        active={selectedTab === 5}
                        initiativeId={initiativeId}
                        isEditable={isEditable}
                        initiativeTitle={title}
                        ownerId={owner}
                        generatingInitiative={generatingInitiative}
                    />
                    <ResourcesTab
                        active={selectedTab === 6}
                        initiativeId={initiativeId}
                        blocks={blocks}
                        isEditable={isEditable}
                    />
                </Flexbox>
            </SidebarContainer>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteConfirmation}
                confirmButtonStyle="danger"
                title="Delete this initiative?"
            >
                <Flexbox>
                    You're about to permanently delete this initiative, and all connected data will
                    be lost.
                </Flexbox>
            </ConfirmationDialog>
            <ConfirmationDialog
                open={openMoveToLiveDialog}
                onClose={onCloseMoveToLiveDialog}
                title="Move this initiative to live?"
                confirmButtonLabel="Move"
                onConfirm={onMoveToLive}
            >
                <Flexbox className={classes('moveToLiveDialogContainer')}>
                    After this all stories will be migrated to product catalogue and you will not be
                    able to make changes in initiative.
                </Flexbox>
            </ConfirmationDialog>
            <ConfirmationDialog
                open={openDuplicateConfirmation}
                onClose={onCloseDuplicationDialog}
                title="Duplicate this initiative?"
                confirmButtonLabel="Duplicate"
                onConfirm={handleDuplicateInitiative}
            >
                <Flexbox>
                    Are you sure you want to duplicate this initiative?
                </Flexbox>
            </ConfirmationDialog>
            {initiativeId && (
                <ChangeLog
                    open={showChangeLog}
                    onClose={onHideChangeLog}
                    initiativeId={initiativeId}
                />
            )}
            <Snackbar
                open={initiativeStatusAlert}
                onClose={closeInitiativeStatusAlert}
                type="error"
            >
                <Flexbox>The Product field cannot be empty!</Flexbox>
            </Snackbar>
            <Snackbar
                open={initiativeDuplicationSuccess}
                type="success"
            >
                <Flexbox vertical>
                    Initiative duplicated successfully !
                </Flexbox>
            </Snackbar>
            <Backdrop open={exportPdfIsLoading} className={classes('exportPdfBackdrop')}>
                <Loader disableShrink />
            </Backdrop>
        </Fragment>
    );
};

export default InitiativeForm;
