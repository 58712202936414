import React from 'react';
import classNames from 'classnames/bind';

import styles from './flexbox.module.scss';
const classes = classNames.bind(styles);

interface FlexboxOwnProps {
  vertical?: boolean,
  align?: boolean,
  justify?: boolean,
  fullWidth?: boolean,
  fullHeight?: boolean,
  wrap?: boolean,
  justifyEnd?: boolean,
  className?: string,
  justifyBetween?: boolean
}
export type FlexboxProps = FlexboxOwnProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
const Flexbox = React.forwardRef<HTMLDivElement, FlexboxProps>(({ className, vertical, align, justify, fullWidth, fullHeight, wrap, justifyEnd, justifyBetween, ...props }, ref) => {
  return <div ref={ref} className={`${classes('flexbox', { vertical, align, justify, fullWidth, fullHeight, wrap, justifyEnd, justifyBetween })}${className ? ` ${className}` : ''}`} {...props} />
})

export default Flexbox