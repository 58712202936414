
import { Button, FilterButton, Flexbox, GroupFilter, Loader, SearchField, SwitchFilter } from 'components';
import { PlusIcon } from 'components/icons';
import { FilterOption } from 'components/FilterButton';
import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './roadmap.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import { SwitchOption } from 'components/SwitchFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getOkrs } from 'pages/Okrs/okrs.api';
import { getInitiatives } from 'pages/Initiatives/initiatives.api';
import { okrsSelector } from 'store/okrs';
import { initiativesSelector } from 'store/initiatives';
import { PreferenceValues, Initiative, InitiativePriority, InitiativeStatus, OKR, Preferences, CalendarPresetsKeys, PreferencesKeys } from 'utils/types';
import {
    addDaysToDate,
    getCurrentMonthEndDate,
    getCurrentMonthStartDate,
    getCurrentYearEndDate,
    getCurrentYearStartDate,
    getPreviousMonthEndDate,
    getPreviousMonthStartDate,
    getPreviousQuarterEndDate,
    getPreviousQuarterStartDate,
    getMaxDate,
    getMinDate,
    getNextMonthEndDate,
    getNextMonthStartDate,
    getNextQuarterEndDate,
    getNextQuarterStartDate,
    getNextYearEndDate,
    getNextYearStartDate,
    getQuarterEndDate,
    getQuarterFromDate,
    getQuarterStartDate
} from 'utils/date';
import { RoadMap } from './components';
import { RoadMapGroup, RoadMapRange, GroupedRoadMap, isKanbanRange, RoadMapRangeRelease, RoadMapRangeKanban, RoadMapGroupItem } from './components/roadmap';
import { calculateProgress, getEnumKeyByEnumValue } from 'utils';
import { productsSelector } from 'store/products';
import { getProducts } from 'pages/Products/products.api';
import { GroupFilterOption } from 'components/GroupFilter';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import DatePicker, { Preset } from 'components/Datepicker';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { userSelector } from 'store/user';
import { teamsSelector } from 'store/teams-slice';
import { activeUsersSelector } from 'store/users-slice';
import { useLazyGetUsersQuery } from 'store/users-api';
import { useLazyGetTeamsQuery } from 'store/teams-api';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);


const groupOptions: GroupFilterOption[] = [
    { id: 0, title: 'OKR' },
    { id: 1, title: 'Product' },
    { id: 2, title: 'Team' },
]

const viewTypes: GroupFilterOption[] = [
    { id: 0, title: 'Release' },
    { id: 1, title: 'Kanban' },
]

const switchOptions: SwitchOption[] = [
    { id: 0, title: 'Quarters' },
    { id: 1, title: 'Months' },
    { id: 2, title: 'Weeks' },
]

const quarterTitles = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec']
const monthTitles = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export enum FilterKeys {
    owner = 'owner',
    status = 'status',
    okr = 'okr',
    product = 'product',
    team = 'team',
    priority = 'priority',
    startDate = 'startDate',
    endDate = 'endDate',
    view = 'view',
    grouping = 'grouping',
    granularity = 'granularity',
    query = 'query',
    calendarSelect = 'calendarSelect'
}

const defaultFilterState = {
    [FilterKeys.okr]: [],
    [FilterKeys.product]: [],
    [FilterKeys.team]: [],
    [FilterKeys.owner]: [],
    [FilterKeys.status]: [],
    [FilterKeys.priority]: []
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const generateRangesForKanban = () => {
    const ranges: RoadMapRangeKanban[] = Object.entries(InitiativeStatus).map(([key, value]) => ({ title: key, status: value }));
    return ranges
}

const generateRangesForRelease = (dateFrom: Date, dateTo: Date, option: SwitchOption) => {
    const ranges: RoadMapRangeRelease[] = [];
    const now = new Date();

    if (option.id === 0) { //Quartes
        const startYear = dateFrom.getFullYear();
        const endYear = dateTo.getFullYear();
        const startQuarter = `${startYear}${getQuarterFromDate(dateFrom)}`;
        const endQuarter = `${endYear}${getQuarterFromDate(dateTo)}`;

        for (let year = startYear; year <= endYear; year++) {
            for (let quarter = 1; quarter <= 4; quarter++) {
                const startDate = new Date();
                startDate.setFullYear(year);
                startDate.setMonth((quarter - 1) * 3);
                startDate.setDate(1);
                startDate.setHours(0, 0, 0, 0);

                const endDate = new Date();
                endDate.setFullYear(year);
                endDate.setMonth(quarter * 3, 0)
                endDate.setHours(23, 59, 59, 59);

                const currentQuarter = `${year}${quarter}`

                if (currentQuarter >= startQuarter && currentQuarter <= endQuarter) {
                    ranges.push({
                        title: `Q${quarter}(${quarterTitles[quarter - 1]})/${year}`,
                        startDate: getMaxDate(dateFrom, startDate),
                        endDate: getMinDate(dateTo, endDate),
                    })
                }
            }
        }
    } else if (option.id === 1) { // Months
        const startYear = dateFrom.getFullYear();
        const endYear = dateTo.getFullYear();
        const startMonth = startYear * 100 + dateFrom.getMonth();
        const endMonth = endYear * 100 + dateTo.getMonth();
        for (let year = startYear; year <= endYear; year++) {
            for (let month = 0; month < 12; month++) {
                const startDate = new Date();
                startDate.setFullYear(year);
                startDate.setMonth(month);
                startDate.setDate(1);
                startDate.setHours(0, 0, 0, 0);

                const endDate = new Date();
                endDate.setFullYear(year);
                endDate.setMonth(month + 1, 0)
                endDate.setHours(23, 59, 59, 59);

                const currentMonth = year * 100 + month

                if (currentMonth >= startMonth && currentMonth <= endMonth) {
                    ranges.push({
                        title: `${monthTitles[month]}/${year}`,
                        startDate: getMaxDate(dateFrom, startDate),
                        endDate: getMinDate(dateTo, endDate)
                    })
                }
            }
        }
    } else if (option.id === 2) { // Weeks
        let currentDate = new Date(dateFrom);
        if (currentDate.getDay() > 0) {
            currentDate.setDate(currentDate.getDate() - currentDate.getDay());
        }
        const onejan = new Date(dateFrom.getFullYear(), 0, 1);
        let weekNumber = Math.ceil((((dateFrom.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);

        while (currentDate <= dateTo) {
            const weekStartYear = currentDate.getFullYear();
            let endWeekDate = addDaysToDate(currentDate, 6);
            let currentMonth = currentDate.getMonth();
            let endMonth = endWeekDate.getMonth();
            let startEnd = '';

            startEnd = `${monthTitles[currentMonth]} ${currentDate.getDate()}-${currentMonth === endMonth ? '' : monthTitles[endMonth] + ' '}${endWeekDate.getDate()}`

            ranges.push({
                title: `Week ${weekNumber}(${startEnd})/${currentDate.getFullYear()}`,
                startDate: currentDate,
                endDate: endWeekDate,
            });
            currentDate = addDaysToDate(currentDate, 7);
            if (currentDate.getFullYear() !== weekStartYear) {
                weekNumber = 1;
            } else {
                weekNumber++;
            }

        }
    }
    const nowRange = ranges.find(range => now >= range.startDate && now <= range.endDate);
    if (nowRange) {
        nowRange.containsNow = true;
    }

    return ranges;
}

const getMaxItemCount = (group: RoadMapGroup) => {
    const values = Object.values(group.columns);
    let max = 2;
    values.forEach(value => {
        if (value.length > max) {
            max = value.length;
        }
    })
    return max;
}

const initiativeBelongsToRange = (initiative: Initiative, range: RoadMapRange) => {
    if (isKanbanRange(range)) {
        return initiative.status === range.status
    } else {
        const releaseDate = initiative.releaseDate && new Date(initiative.releaseDate);
        return releaseDate && releaseDate >= range.startDate && releaseDate <= range.endDate
    }
}

const getInitiativesCount = (rangeData: RoadMapRange[], groupedData: RoadMapGroup[]) => {
    const titleCount: { [title: string]: number } = {};

    groupedData.forEach(group => {
        Object.keys(group.columns).forEach(columnName => {
            const columnItems = group.columns[columnName];
            const title = rangeData.find(item => item.title === columnName)?.title;
            if (title) {
                titleCount[title] = (titleCount[title] || 0) + columnItems.length;
            }
        });
    });

    const resultArray = rangeData.map(item => ({
        ...item,
        count: titleCount[item.title] || 0
    }));

    return resultArray;
}

export default () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();

    const [getUsers] = useLazyGetUsersQuery();
    const [getTeams] = useLazyGetTeamsQuery();

    const [groupValue, setGroupValue] = useState<GroupFilterOption>(groupOptions[0]);
    const [viewValue, setViewValue] = useState<GroupFilterOption>(viewTypes[0]);
    const [dateFrom, setDateFrom] = useState<Date | null>(getQuarterStartDate(new Date()));
    const [dateTo, setDateTo] = useState<Date | null>(getQuarterEndDate(new Date()));
    const [switchValue, setSwitchValue] = useState<SwitchOption>(switchOptions[1]);
    const [groupedRoadMap, setGroupedRoadMap] = useState<GroupedRoadMap | null>(null);
    const [ranges, setRanges] = useState<RoadMapRange[]>([]);


    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');

    const [ownerOptions, setOwnerOptions] = useState<FilterOption[]>([]);
    const [okrOptions, setOkrOptions] = useState<FilterOption[]>([]);
    const [productOptions, setProductOptions] = useState<FilterOption[]>([]);
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FilterOption[]>([]);
    const [priorityOptions, setPriorityOptions] = useState<FilterOption[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [calendarSelect, setCalendarSelect] = useState<string | undefined>();
    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisMonth, title: 'This month' },
        { id: CalendarPresetsKeys.nextMonth, title: 'Next month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])

    const [searchParams, setSearchParams] = useSearchParams();

    const okrs = useSelector(okrsSelector);
    const products = useSelector(productsSelector);
    const allInitiatives = useSelector(initiativesSelector);
    const teams = useSelector(teamsSelector);
    const users = useSelector(activeUsersSelector);
    const user = useSelector(userSelector);

    useEffect(() => {

        setIsLoading(true);
        loadData();
    }, [])

    const loadData = async () => {

        await Promise.all([
            dispatch(getOkrs()),
            dispatch(getProducts()),
            dispatch(getInitiatives()),
            getTeams({ workspaceId }),
            getUsers({ workspaceId }),
            loadPreferences(),
        ])
        setIsLoading(false);
    }

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.roadmap))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {

            const filters: PreferenceValues<FilterKeys> = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;
            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true });
            }
        }
    }

    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }

        setOwnerOptions(options)
    }, [users, user])

    useEffect(() => {
        if (!isLoading) {

            if (ownerOptions.length) {
                const ownerIdsString = searchParams.get(FilterKeys.owner);
                if (ownerIdsString) {
                    const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                    setFilterValues({ type: 'update', key: FilterKeys.owner, payload: ownerOptions.filter(option => ownerIds.includes(option.id)) })
                }
            }

            const okr = okrs.map(okr => ({ id: okr.id, title: okr.objective || '' }))
            setOkrOptions(okr);

            if (okr.length) {
                const okrIdsString = searchParams.get(FilterKeys.okr);
                if (okrIdsString) {
                    const okrIds = okrIdsString.split(',').map(id => parseInt(id));
                    setFilterValues({ type: 'update', key: FilterKeys.okr, payload: okr.filter(option => okrIds.includes(option.id)) })
                }
            }

            const product = products.map(product => ({ id: product.id, title: product.title }));
            setProductOptions(product);

            if (product.length) {
                const productIdsString = searchParams.get(FilterKeys.product);
                if (productIdsString) {
                    const productIds = productIdsString.split(',').map(id => parseInt(id));
                    setFilterValues({ type: 'update', key: FilterKeys.product, payload: product.filter(option => productIds.includes(option.id)) })
                }
            }

            const team = teams.map(team => ({ id: team.id, title: team.name || '' }));
            setTeamOptions(team);

            if (team.length) {
                const teamIdsString = searchParams.get(FilterKeys.team);
                if (teamIdsString) {
                    const teamIds = teamIdsString.split(',').map(id => parseInt(id));
                    setFilterValues({ type: 'update', key: FilterKeys.team, payload: team.filter(option => teamIds.includes(option.id)) })
                }
            }

            const status = Object.keys(InitiativeStatus).map((key, index) => ({ id: index, title: key }));
            setStatusOptions(status);

            if (status.length) {
                const statusesString = searchParams.get(FilterKeys.status);
                if (statusesString) {
                    const statuses = statusesString.split(',');
                    setFilterValues({ type: 'update', key: FilterKeys.status, payload: status.filter(option => statuses.includes(option.title)) })
                }
            }

            const priority = Object.keys(InitiativePriority).map((key, index) => ({ id: index, title: key }));
            setPriorityOptions(priority);

            if (priority.length) {
                const prioritiesString = searchParams.get(FilterKeys.priority);
                if (prioritiesString) {
                    const priorities = prioritiesString.split(',');
                    setFilterValues({ type: 'update', key: FilterKeys.priority, payload: priority.filter(option => priorities.includes(option.title)) })
                }
            }

            const grouping = searchParams.get(FilterKeys.grouping);
            if (grouping) {
                const groupId = parseInt(grouping);
                const group = groupOptions.find(group => group.id === groupId);
                if (group) {
                    setGroupValue(group);
                }
            }

            const viewType = searchParams.get(FilterKeys.view);
            if (viewType) {
                const viewId = parseInt(viewType);
                const view = viewTypes.find(view => view.id === viewId);
                if (view) {
                    setViewValue(view)
                }
            }

            const granularityType = searchParams.get(FilterKeys.granularity);
            if (granularityType) {
                const granularityId = parseInt(granularityType);
                const granularity = switchOptions.find(option => option.id === granularityId);
                if (granularity) {
                    setSwitchValue(granularity);
                }
            }

            const startDate = searchParams.get(FilterKeys.startDate);
            if (startDate) {
                setDateFrom(new Date(startDate));
            }

            const endDate = searchParams.get(FilterKeys.endDate);
            if (endDate) {
                setDateTo(new Date(endDate));
            }

            const presetType = searchParams.get(FilterKeys.calendarSelect)
            if (presetType) {
                setCalendarSelect(presetType)
            }

            const query = searchParams.get(FilterKeys.query);
            if (query) {
                setSearchValue(query);
            }
        }

    }, [isLoading]);



    useEffect(() => {
        if (canUpdatePreferences && dateTo) {

            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const statuses = filterValues[FilterKeys.status].map(filter => filter.title);
            const productIds = filterValues[FilterKeys.product].map(filter => filter.id);
            const okrIds = filterValues[FilterKeys.okr].map(filter => filter.id);
            const teamIds = filterValues[FilterKeys.team].map(filter => filter.id);
            const priorities = filterValues[FilterKeys.priority].map(filter => filter.title);

            const filterKeys: PreferenceValues<FilterKeys> = {
                [FilterKeys.grouping]: groupValue.id.toString(),
                [FilterKeys.view]: viewValue.id.toString(),
                [FilterKeys.granularity]: switchValue.id.toString(),
            };

            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if (statuses.length) {
                filterKeys[FilterKeys.status] = statuses.join(',');
            }

            if (productIds.length) {
                filterKeys[FilterKeys.product] = productIds.join(',');
            }

            if (okrIds.length) {
                filterKeys[FilterKeys.okr] = okrIds.join(',');
            }

            if (teamIds.length) {
                filterKeys[FilterKeys.team] = teamIds.join(',');
            }

            if (priorities.length) {
                filterKeys[FilterKeys.priority] = priorities.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }

            if (dateFrom && dateTo && calendarSelect === undefined) {
                filterKeys[FilterKeys.startDate] = dateFrom.toUTCString();
                filterKeys[FilterKeys.endDate] = dateTo.toUTCString();
            }

            if (calendarSelect) {
                filterKeys[FilterKeys.calendarSelect] = calendarSelect
            }

            dispatch(updatePreferences(filterKeys, PreferencesKeys.roadmap));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [groupValue, viewValue, switchValue, dateTo, filterValues, searchDebounceValue])

    useEffect(() => {
        if (calendarSelect === CalendarPresetsKeys.thisMonth) {
            setDateFrom(getCurrentMonthStartDate());
            setDateTo(getCurrentMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextMonth) {
            setDateFrom(getNextMonthStartDate());
            setDateTo(getNextMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.thisQuarter) {
            setDateFrom(getQuarterStartDate(new Date()));
            setDateTo(getQuarterEndDate(new Date()));
        } else if (calendarSelect === CalendarPresetsKeys.nextQuarter) {
            setDateFrom(getNextQuarterStartDate(new Date()));
            setDateTo(getNextQuarterEndDate(new Date()));
        } else if (calendarSelect === CalendarPresetsKeys.thisYear) {
            setDateFrom(getCurrentYearStartDate());
            setDateTo(getCurrentYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextYear) {
            setDateFrom(getNextYearStartDate());
            setDateTo(getNextYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastMonth) {
            setDateFrom(getPreviousMonthStartDate());
            setDateTo(getPreviousMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastQuarter) {
            setDateFrom(getPreviousQuarterStartDate());
            setDateTo(getPreviousQuarterEndDate());
        }

        setPresets((current) => {
            return current.map(p => ({ ...p, isSelected: p.id === calendarSelect }))
        })

    }, [calendarSelect])

    const getOkrById = (id: number) => {
        return okrs.find(okr => okr.id === id);
    }

    const getOKrProgress = (okr: OKR) => {
        let progress = 0;
        if (okr.keyResults.length) {
            const allProgress = okr.keyResults.reduce((prevValue, kr) => {
                return prevValue + calculateProgress(kr.startValue || 0, kr.targetValue || 0, kr.metric.currentValue || 0)
            }, 0)
            progress = allProgress / okr.keyResults.length;
        }
        return progress
    }

    const addToGroup = (groups: RoadMapGroup[], range: RoadMapRange | undefined, initiative: Initiative, itemParams: RoadMapGroupItem) => {

        let group = groups.find(g => g.item.id === itemParams.id);

        if (!group) {
            group = {
                item: itemParams,
                columns: {}
            }
            groups.push(group);
        }
        if (range) {
            if (!group.columns[range.title]) {
                group.columns[range.title] = []
            }
            if (!group.columns[range.title].some(i => initiative.id === i.id)) {
                group.columns[range.title].push(initiative)
            }
        }
    }

    const moveGroupItemToLast = (groups: RoadMapGroup[]) => {
        const index = groups.findIndex(g => g.item.id === -1)

        if (index !== -1) {
            const unassignedItem = groups.splice(index, 1)[0]
            groups.push(unassignedItem)
            return groups
        }
    }

    const groupByOKRs = (initiatives: Initiative[], ranges: RoadMapRange[]) => {
        const groups: RoadMapGroup[] = [];
        initiatives.forEach(initiative => {
            const range = ranges.find(r => initiativeBelongsToRange(initiative, r));
            if (!range) {
                return;
            }

            if (initiative.okrs.length) {
                initiative.okrs.forEach(okr => {
                    const currentOkr = getOkrById(okr.okrId);

                    if (currentOkr) {
                        const progress = getOKrProgress(currentOkr)
                        addToGroup(groups, range, initiative, { id: currentOkr.id, title: currentOkr.objective || '', progress, maxCount: 0, url: '/okrs/okr' })
                    }
                }
                )
            } else {
                addToGroup(groups, range, initiative, { id: -1, title: 'OKR not selected', maxCount: 0 })
            }
        });

        moveGroupItemToLast(groups)

        groups.forEach(group => {
            group.item.maxCount = getMaxItemCount(group);
        })
        return groups;
    }

    const groupByProducts = (initiatives: Initiative[], ranges: RoadMapRange[]) => {
        const groups: RoadMapGroup[] = [];
        initiatives.forEach(initiative => {
            const range = ranges.find(r => initiativeBelongsToRange(initiative, r));

            if (initiative.products.length) {
                initiative.products.forEach(product => {
                    addToGroup(groups, range, initiative, { id: product.id, title: product.title, maxCount: 0, url: '/catalog/product' })
                })
            } else {
                addToGroup(groups, range, initiative, { id: -1, title: 'Product not selected', maxCount: 0 })
            }
        });

        moveGroupItemToLast(groups)

        groups.forEach(group => {
            group.item.maxCount = getMaxItemCount(group);
        })
        return groups;
    }

    const groupByTeams = (initiatives: Initiative[], ranges: RoadMapRange[]) => {
        const groups: RoadMapGroup[] = [];
        initiatives.forEach(initiative => {
            const range = ranges.find(r => initiativeBelongsToRange(initiative, r));

            if (initiative.teams.length) {
                initiative.teams.forEach(team => {
                    addToGroup(groups, range, initiative, { id: team.id, title: team.name, maxCount: 0 })
                })
            } else {
                addToGroup(groups, range, initiative, { id: -1, title: 'Team not selected', maxCount: 0 })
            }
        })

        moveGroupItemToLast(groups)

        groups.forEach(group => {
            group.item.maxCount = getMaxItemCount(group);
        })
        return groups
    }

    useEffect(() => {

        if (dateFrom && dateTo) {

            const mainColumnTitle = groupValue.title;
            const secondColumnTitle = viewValue.title;

            // filter by date
            let initiatives = allInitiatives.filter(initiative => {
                const releaseDate = initiative.releaseDate && new Date(initiative.releaseDate);
                const startDate = initiative.startDate && (new Date(initiative.startDate));
                const endDate = initiative.endDate && (new Date(initiative.endDate));

                if (releaseDate) {
                    releaseDate.setHours(0, 0, 0)
                }

                if (startDate) {
                    startDate.setHours(0, 0, 0)
                }

                if (endDate) {
                    endDate.setHours(0, 0, 0)
                }

                const byReleaseDate = releaseDate && releaseDate >= dateFrom && releaseDate <= dateTo
                const byStartDate = startDate && startDate >= dateFrom && startDate <= dateTo
                const byEndDate = (endDate && endDate >= dateFrom && endDate <= dateTo) || (startDate && startDate <= dateFrom && endDate && endDate >= dateTo)

                if (byReleaseDate || (viewValue.id === 1 && byStartDate || byEndDate)) {
                    return true;
                }

                return false;
            })

            // filter by search
            initiatives = initiatives.filter(initiative => initiative.title && initiative.title.toLowerCase().includes(searchDebounceValue.toLowerCase()))

            // filter by okr
            initiatives = initiatives.filter(initiative => {
                const initiativeOkrs = initiative.okrs.map(okr => okr.okrId);
                if (!filterValues[FilterKeys.okr].length || filterValues[FilterKeys.okr].some(filter => initiativeOkrs.includes(filter.id))) {
                    return true;
                }
                return false;
            })

            // filter by products
            initiatives = initiatives.filter(initiative => {
                if (!filterValues[FilterKeys.product].length || filterValues[FilterKeys.product].some(filter => initiative.products.find(product => product.id === filter.id))) {
                    return true;
                }
                return false;
            })

            // filter by teams
            initiatives = initiatives.filter(initiative => {
                const initiativeTeams = initiative.teams.map(team => team.id);
                if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => initiativeTeams.includes(filter.id))) {
                    return true;
                }
                return false;
            })

            // filter by Owner
            initiatives = initiatives.filter(initiative => {
                if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === initiative.owner.id)) {
                    return true;
                }
                return false;
            })

            // filter by status

            initiatives = initiatives.filter(initiative => {
                const initiativeStatus = getEnumKeyByEnumValue(InitiativeStatus, initiative.status);
                if (!filterValues[FilterKeys.status].length || filterValues[FilterKeys.status].some(filter => filter.title === initiativeStatus)) {
                    return true;
                }
                return false
            })

            // filter by priority

            initiatives = initiatives.filter(initiative => {
                const initiativePriority = getEnumKeyByEnumValue(InitiativePriority, initiative.priority);
                if (!filterValues[FilterKeys.priority].length || filterValues[FilterKeys.priority].some(filter => filter.title === initiativePriority)) {
                    return true;
                }
                return false
            })

            let ranges: RoadMapRange[] = [];

            if (viewValue.id === 1) { // Kanban
                ranges = generateRangesForKanban();
                if (filterValues[FilterKeys.status].length) {
                    ranges = ranges.filter(range => {
                        return filterValues[FilterKeys.status].some(filter => range.title === filter.title)
                    })

                }
            } else {
                ranges = generateRangesForRelease(dateFrom, dateTo, switchValue);
            }

            let groups: RoadMapGroup[] = []
            if (groupValue.id === 0) { // OKR
                groups = groupByOKRs(initiatives, ranges);
                if (filterValues[FilterKeys.okr].length) {

                    groups = groups.filter(group => filterValues[FilterKeys.okr].some(filter => filter.id === group.item.id))
                }
            } else if (groupValue.id === 1) { // Product
                groups = groupByProducts(initiatives, ranges);
            } else if (groupValue.id === 2) { // Teams
                groups = groupByTeams(initiatives, ranges);

                if (filterValues[FilterKeys.team].length) {
                    groups = groups.filter(group => filterValues[FilterKeys.team].some(filter => filter.id === group.item.id))
                }
            }

            const roadMap: GroupedRoadMap = {
                groups,
                mainColumnTitle,
                secondColumnTitle,
            }

            setGroupedRoadMap(roadMap);
            setRanges(getInitiativesCount(ranges, groups));
        }


    }, [allInitiatives, groupValue, viewValue, switchValue, dateFrom, dateTo, okrs, filterValues, searchDebounceValue])

    const onGroupChange = (value: GroupFilterOption) => {
        setGroupValue(value);
        setCanUpdatePreferences(true)
    }
    const onViewTypeChange = (value: GroupFilterOption) => {
        setViewValue(value);
        setCanUpdatePreferences(true)
    }

    const onSwitchValueChange = (value: SwitchOption) => {
        setSwitchValue(value);
        setCanUpdatePreferences(true);
    }

    const onDateChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        setCalendarSelect(undefined)
        setDateFrom(startDate);
        setDateTo(endDate);
        if (endDate) {
            setCanUpdatePreferences(true)
        }
    }

    const selectCalendarRange = (id: string) => {
        setCalendarSelect(id);
        setCanUpdatePreferences(true)
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value });
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('')
        setFilterValues({ type: 'reset' });
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] });
        setCanUpdatePreferences(true)
    }

    const createNewInitiative = () => {
        navigate('/initiatives/initiative');
    }

    const isKanbanView = viewValue.id === 1;


    const viewSettings = () => {
        return (
            <Flexbox className={classes('filterGroup')}>
                <Flexbox className={classes('filterContainer')}>
                    <GroupFilter
                        title='Setup your view'
                        groups={[
                            {
                                options: viewTypes,
                                value: viewValue,
                                onChange: onViewTypeChange,
                                multiple: false,
                                title: 'View Mode',
                            },
                            {
                                options: groupOptions,
                                value: groupValue,
                                onChange: onGroupChange,
                                multiple: false,
                                title: 'Group By',
                            }
                        ]}

                    />
                </Flexbox>
            </Flexbox>
        )
    }


    return (
        isLoading ? <Flexbox fullWidth align justify><Loader disableShrink /></Flexbox> :
            <Flexbox vertical fullWidth className={classes('mainContainer')}>
                <Flexbox vertical className={classesInfo('headerContainer')}>
                    <Flexbox className={classesInfo('headerInfoTop')}>
                        <Flexbox>
                            <Flexbox className={classesInfo('headerTitle')}>
                                RoadMap
                            </Flexbox>
                            <Flexbox className={classesInfo('headerTitleSmall')}>
                                <Flexbox className={classesInfo('headerTitleLine')}>
                                    |
                                </Flexbox>
                                {(() => {
                                    switch (viewValue.id) {
                                        case 0:
                                            return 'Release Calendar'
                                        case 1:
                                            return 'Kanban Board'
                                        default:
                                            return null
                                    }
                                })()}
                            </Flexbox>
                        </Flexbox>
                        <Flexbox>
                            {groupedRoadMap && allInitiatives.length > 0 &&
                                <Button variant='text' className={classesInfo('createButtonText')} onClick={createNewInitiative}>
                                    Create initiative
                                    <PlusIcon />
                                </Button>
                            }
                            {viewSettings()}
                        </Flexbox>
                    </Flexbox>
                    {groupedRoadMap && allInitiatives.length > 0 &&
                        <Flexbox className={classesInfo('headerInfo')}>
                            <Flexbox>
                                <SearchField
                                    className={classesInfo('searchInput')}
                                    value={searchValue}
                                    onChange={onSearchValueChange}
                                    onClear={onSearchClear}
                                    placeholder='Search Initiatives'
                                />
                                <FilterButton
                                    options={okrOptions}
                                    value={filterValues[FilterKeys.okr]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.okr, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.okr)}
                                    multiple
                                    label={'OKRs'}
                                    className={classesInfo('filterButton')}
                                />
                                <FilterButton
                                    options={productOptions}
                                    value={filterValues[FilterKeys.product]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.product, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.product)}
                                    multiple
                                    label={'Products'}
                                    className={classesInfo('filterButton')}
                                />
                                <FilterButton
                                    options={teamOptions}
                                    value={filterValues[FilterKeys.team]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.team)}
                                    multiple
                                    label={'Teams'}
                                    className={classesInfo('filterButton')}
                                />
                                <FilterButton
                                    options={ownerOptions}
                                    value={filterValues[FilterKeys.owner]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                    multiple
                                    label={'Owner'}
                                    className={classesInfo('filterButton')}
                                    keepFirstOption
                                />
                                <FilterButton
                                    options={statusOptions}
                                    value={filterValues[FilterKeys.status]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.status, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.status)}
                                    multiple
                                    sortAlphabetically={false}
                                    label={'Status'}
                                    className={classesInfo('filterButton')}
                                />
                                <FilterButton
                                    options={priorityOptions}
                                    value={filterValues[FilterKeys.priority]}
                                    onChange={(value) => onFilterValueChange(FilterKeys.priority, value)}
                                    onFilterReset={() => onFilterReset(FilterKeys.priority)}
                                    multiple
                                    sortAlphabetically={false}
                                    label={'Priority'}
                                    className={classesInfo('filterButton')}
                                />
                                {Object.values(filterValues).some(v => v.length !== 0) &&
                                    <Flexbox className={classesInfo('resetButtonContainer')}>
                                        <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                            Reset
                                        </Button>
                                    </Flexbox>
                                }
                            </Flexbox>
                            <Flexbox className={classes('rightActions')}>
                                <DatePicker
                                    onChange={onDateChange}
                                    startDate={dateFrom}
                                    endDate={dateTo}
                                    presets={{
                                        onPresetSelect: selectCalendarRange,
                                        presets,
                                    }}
                                    monthsShown={2}
                                    selectsRange
                                    type='button'
                                    dateFormat="MM.dd.yy"
                                />
                            </Flexbox>
                        </Flexbox>
                    }
                </Flexbox>
                <Flexbox className={classes('roadMapContainer')}>
                    {groupedRoadMap && <RoadMap roadMap={groupedRoadMap} ranges={ranges} isKanbanVew={isKanbanView} allInitiatives={allInitiatives} />}
                    {!isKanbanView && allInitiatives.length > 0 && <Flexbox className={classes('switch')}>
                        <Flexbox className={classes('filterContainer')}>
                            <SwitchFilter
                                options={switchOptions}
                                value={switchValue}
                                onChange={onSwitchValueChange}
                            />
                        </Flexbox>
                    </Flexbox>}
                </Flexbox>
            </Flexbox>
    )
}