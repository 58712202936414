import { Button, type ButtonProps as MuiButtonProps } from '@mui/material'
import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import Loader from 'components/Loader';

const classes = classNames.bind(styles);

interface CommonButtonProps extends MuiButtonProps {
    loading?: boolean
}

const CommonButton: FC<CommonButtonProps> = ({ className, children, loading, ...props }) => {
    return (
        <Button
            {...props}
            className={classes('commonButton', className)}
        >
            {children}
            {loading && <Loader size={16} />}
        </Button>
    )
}

export default CommonButton